header {
  float: left;
  width: 100%;
  position: fixed;
  z-index: 1000;

 .arealogotart {
    width: 400px;
    margin: 10px auto;

    @include media(xs) {
      float: left;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

        p {
          margin: 0 5px;
          font-weight: bold;
        }
    }

}

.icocheck{
  float: left;
  width: 100%;
  height: 50px;
  margin: 10px 0;
  background: url('img/ico-aceptada.svg') no-repeat center;
  background-size: 45px;
}

.icocheckoff{
  float: left;
  width: 100%;
  height: 50px;
  margin: 10px 0;
  background: url('img/ico-aceptada.svg') no-repeat center;
  background-size: 45px;
}





  .main_header {
    padding: 5px;
    float: left;
    width: 100%;
    background: #ff643e; /* Old browsers */
    background: -moz-linear-gradient(left, #ff643e 0%, #fa4537 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #ff643e 0%,#fa4537 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #ff643e 0%,#fa4537 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff643e', endColorstr='#fa4537',GradientType=1 ); /* IE6-9 */

    .form-group {
      margin-bottom: 0;
      padding-left: 10px;
    }


    .spc_header {
      display: flex;
      justify-content: space-between;
    }
    .ico-pagalo {
      float: left;
      width: 150px;
      height: 45px;
      padding: 3px;

        a {
          float: left;
          width: 100%;
          height: 100%;
          background: url('img/logopagalo_b.svg') no-repeat center;
          background-size: 85%;
        }
    }

    .form--control {
      float: left;
      width: 100%;
      margin-top: 5px;
      background: rgba(#f9f9f9,.8);
      border: none !important;
      box-shadow: none !important;
      transition: none !important;
      height: auto !important;
      padding-left: 4px;
      padding-top: 7px;
      padding-bottom: 5px;
      font-size: 15px;
      border-radius: 4px 0 0 4px;
      &:focus {
        border-color: #f9f9f9;
        background: #f9f9f9;
        border: none !important;
        outline: 0;
      }
    }
  }
}

.btn-busquedat {
  float: left;
  width: 100%;
  height: 36px;
  padding: 7px 10px 7px 10px;
  border-radius:0 5px 5px 0 !important;
  border:none !important;
  background:#f9f9f9 url('img/icobuscart.svg') no-repeat center !important;
  background-size: 15px !important;
  color: $principal;
  font-size: 17px;
  font-weight: normal;

  &:hover{
    background: #f9f9f9 url('img/icobuscart.svg') no-repeat center !important;
    background-size: 15px !important;
  }
}

.form_container {
  float: left;
  display: flex
}

.ico_carrito {
  margin-left: 10px;
  float: left;
  width: 50px;
  height: 100%;
  background: url('img/icocart.svg') no-repeat center;
  background-size: 70%;
  cursor: pointer;
}


.cant_carrito{
  background: #2942A9;
  border-radius: 50px;
  height: 20px;
  width: 20px;
  color:white;
  text-align: center;
  font-weight: bold;  
  font-size: 14px;
  position: relative;
  left: -12px;
  top: 10px; 

}


.banner_container {
  float: left;
  width: 100%;
  padding: 0;

    .banner_item {
      margin-top: 10px;
      float: left;
      width: 100%;
      height: 310px;
      background: #f9f9f9;
      border: 1px solid #ddd;
      box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.12);
      // position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      @include media(xs) {
        height: 200px;
      }

        .banner_logo {
          float: left;
          width: 130px;
          height: 130px;
          background: #f9f9f9;
          box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.12);
          border: 10px solid #fff;
          position: absolute;
          bottom: -60px;

            @include media(xs) {
              top: 185px;
              bottom: 0;
            }

             a{
                 width: 80px;
              }
        }

        .toplper {
          @include media(xs) {
            top: 133px;
          }
        }

        p {
          float: left;
          width: 100px;
          font-size: 18px;
          font-weight: bold;
          text-align: center;
          line-height: 18px;
          position: absolute;
          bottom: -120px;

            @include media(xs) {
              top: 330px;
              bottom: 0;
            }
        }

        a{
          width: 100px;
        }
    }
}

.mtopba{
  margin-top:54px !important;
}



.container_productost {
  float: left;
  width: 100%;
  margin-top: 145px;
}

.container__menul {
  float: left;
  width: 100%;
  position: fixed;
  max-width: 150px;
  top: 470px;
  transition: all 0.5s ease;
  height: 275px;
  overflow-y: auto;

    &::-webkit-scrollbar {
      width: 2px;
      background-color: #ccc;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #2942a9;
      background: linear-gradient(to right, #2942a9 0%, #002883 100%);
    }

    @include media(xs) {
      display: none;
    }

    .menul {
      float: left;
      padding: 0;
      list-style: none;

        .item__menul {
          float: left;
          width: 95%;
          font-size: 13px;
          font-weight: 400;
          padding: 7px 10px;
          border-radius: 5px;

          &:hover {
            font-weight: bold;
            background: #fff url('img/ico-circulo.svg') no-repeat 125px center;
            background-size: 10px;
            box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.12);
            cursor: pointer;
          }
        }
    }
}

.container_menuphone {
  display: none;

  @include media(xs) {
    display: block;
    float: left;
    width: 100%;
    height: 60px;
    background: transparent;
    padding: 10px;
    position: relative;
    top: 60px;

      .btn_menu {
        float: left;
        width: 100px;
        height: 100%;
        background: url('img/icomenut.svg') no-repeat left center;
        background-size: 25%;
      }
  }
}

.mtopmt {
  top: 245px;
  transition: all 0.5s ease;
}

.card__productos {
  float: left;
  width: 100%;
  margin-bottom: 25px;

    .productos_item {
      float: left;
      width: 100%;
      height: 250px;
      background: #fff;
      box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.12);
      border-radius: 10px;
      overflow: hidden;
      margin-bottom: 30px;

        @include media(xs) {
          overflow: visible;
        }

        .producto_img {
          cursor: pointer;
          float: left;
          width: 100%;
          height: 150px;
          // background: url('img/imgmc.png') no-repeat center;
          background-size: cover !important;
           h4 {
            position: absolute;
            font-size: 40px;
            color: #fff;
            font-weight: normal;
            margin: 20% auto;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 50px;
            height: 50px;
            text-align: center;
           }
        }

        .producto_descripcion {
          float: left;
          width: 100%;
          margin-top: 5px;

            .title_producto {
              float: left;
              width: 100%;
                p {
                  float: left;
                  width: 100%;
                  text-align: center;
                  margin-bottom: 3px;
                  font-style: 14px;
                  font-weight: bold;
                }

                a {
                  float: left;
                  width: 100%;
                  margin-top: 0;
                  margin-bottom: 10px;
                }
            }

            .descri_producto {
              float: left;
              width: 100%;
              margin-top: 3px;
              padding: 0 5px;
                p {
                  float: left;
                  width: 100%;
                  text-align: left;
                  font-size: 13px;
                  color: #ccc;
                  font-weight: 400;
                  margin-bottom: 3px;
                }
            }
        }

        .opciones_producto {
          float: left;
          width: 100%;
          padding: 0 5px;

            .precioproducto {
              float: left;
               p {
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 0;
                text-align: left;
                color: #2942A9;
               }
            }

            .ico_agregar {
              float: left;
              width: 35px;
              height: 35px;
              border-radius: 50%;
              background: #ff643e url('img/icomas_b.svg') no-repeat center;
              background-size: 20px;
              box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.12);
              position: absolute;
              right: 0;
              bottom: 20px;
              cursor: pointer;

                @include media(xs) {
                  position: relative;
                  bottom: -10px;
                  right: -185px;
                }

                &:hover {
                  background: #fff url('img/icomas.svg') no-repeat center;
                  background-size: 20px;
                }
            }
        }
    }
}

 #area_carrito {
  position: fixed;
  background: #fff;
  box-shadow: 1px 0px 2px 0px rgba(0, 0, 0, 0.15);
  height: 100%;
  width: 365px;
  right: 0;
  top: 0;
  z-index: 200;
  animation: .3s craparecer;
  overflow-y:auto;

  @include media(xs) {
    width: 100%;
  }
}

@keyframes crocultar {
  from {
    right: 0;
  }
  to {
    right: -30%;
  }
}

@keyframes craparecer {
  from {
    right: -345px;
  }
  to {
    right: 0;
  }
}

#area_carrito.ng-leave {
  animation: .3s crocultar;

  @include media(xs) {
    animation: .1s crocultar;
  }
}

.head_carrito {
  float: left;
  width: 100%;
  padding: 13px;
  background: #ff643e; /* Old browsers */
  background: -moz-linear-gradient(left, #ff643e 0%, #fa4537 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #ff643e 0%,#fa4537 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #ff643e 0%,#fa4537 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff643e', endColorstr='#fa4537',GradientType=1 ); /* IE6-9 */

    p {
      float: left;
      width: 75%;
      margin: 0;
      font-size: 18px;
      font-weight: normal;
      text-align: center;
      color: #fff;
      padding-left: 85px;
    }

    .icocerrarc {
      float: left;
      width: 25%;
      height: 25px;
      background: url('img/ico-regresar.svg') no-repeat right center;
      background-size: 22px;
      cursor: pointer;
    }
}


.labelcar{
    font-weight: 600!important;
    font-size: 12px;
    letter-spacing: 0.02em;
    padding-left: 10px !important;

}

.carrito_container {
  float: left;
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  height: calc(100% - 60px);
}

.body_carrito {
  float: left;
  width: 100%;
  height: calc(100% - -15%);
  overflow: auto;
  margin-top: 5px;

      &::-webkit-scrollbar {
        width: 8px;
        background-color: #ccc;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #FB4A38;
      }

    .bodyc__item {
      float: left;
      width: 100%;
      padding: 5px;
      height: 100px;
      background: #f9f9f9;
      border-bottom: 1px solid #ccc;

        .img_producto {
          float: left;
          width: 80px;
          height: 60px;
          margin-right: 10px;
          margin-top: 10px;

            img {
              display: block;
              max-width: 100%;
              width: 100%;
            }
        }

        .info_producto {
          float: left;
          width: calc(245px - 40px);

            .info__opciones {
              float: left;
              width: 100%;
              height: 100%;

                .wformdatos {
                  float: left;
                  width: 70%;
                }

                h3 {
                  font-size: 15px;
                  font-weight: bold;
                  margin-top: 15px;
                  margin-bottom: 0;
                }

                p {
                  font-size: 15px;
                  font-weight: normal;
                  margin-top: 10px;
                  margin-bottom: 0;
                  color: #FB4A38;
                }

                .contmasform {
                    margin-top: 5px;
                  margin-right: 5px;
                  float: right;
                  display: flex;
                  flex-direction: column-reverse;
                  justify-content: center;
                  align-items: center;
                  width: 30px;
                  background: #fff;
                  border-radius: 5px;
                  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);

                  .btn_menos {
                    float: left;
                    width: 30px;
                    height: 30px;
                    background: url('img/ico-restar.svg') no-repeat center;
                     background-size: 13px;
          
                    border-radius: 0px;
                    cursor: pointer;
                    color: #ff643e;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 0px;
                    font-weight: bold;
                      &:hover{
                        background: #ddd url('img/ico-restar.svg') no-repeat center;
                     background-size: 14px;
                    }
                  }

                  .btn_mas {
                    float: left;
                    width: 30px;
                    height: 30px;
                     background: url('img/ico-sumar.svg') no-repeat center;
                     background-size: 15px;

                    border-radius: 0px;
                    cursor: pointer;
                     color: #ff643e;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 0px;
                    font-weight: bold;

                    &:hover{
                       background: #ddd url('img/ico-sumar.svg') no-repeat center;
                     background-size: 16px;
                    }
                  }
                }
            }
        }

        .opciones_productoc {
          float: left;
          width: calc(40px - 10px);
          height: 100%;
          display: flex;
          justify-content: center;

            .ico_eliminar {
              margin-left: 5px;
              float: left;
              width: 100%;
              height: 30px;
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
              background:  url('img/ico-elitem.svg') no-repeat center;
               background-size: 15px;
            }
        }
    }
}

.footer_carrito {
  float: left;
  width: 100%;
  height: calc(100% - 65%);
  background: #f4f4f4;
  box-shadow: 2px -3px 5px 0 #c9c9c9;
  z-index: 1;
  padding: 15px;

    .cont_sub {
      float: left;
      width: 100%;
      display: flex;
      justify-content: space-between;

       a {
          float: left;
          width: 100%;
          padding: 5px 0;
          border:1px solid #ccc;
          border-radius: 5px;
          margin-bottom: 0px;
          font-weight: 400;
          font-size: 15px;
          text-align: center;
          cursor:pointer;
          color:#333;

          &:hover{
            background: #fff;
            text-decoration: none;
          }

        }
    }

    .cont_total {
      float: left;
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 35px;

        p {
          float: left;
          margin-bottom: 3px;
          font-weight: bold;
          font-size: 18px;
        }
    }

    .container_btnpago {
      float: left;
      width: 100%;
      margin-top: 5px;
      padding: 15px;
      display: flex;
      justify-content: center;
      align-items: center;

        .btnpago {
          float: left;
          width: 75%;
          padding: 10px;
          border-radius: 5px;
          border: none !important;
          text-decoration: none;
          background: #2942a9;
          background: linear-gradient(to right, #2942a9 0%, #002883 100%);
          border:1px solid #2942a9;
          box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
          color: #fff;
          font-size: 17px;
          font-weight: normal;
          text-align: center;
          cursor: pointer;
        }
    }
}

.carrito-open {
  overflow: hidden;
  position: relative;
}

.pago_container {
  float: left;
  width: 100%;
  height: calc(100% - 10%);
}

.nya-bs-select.relcont.btn-group .dropdown-menu {
  position: relative;
  width: 75%;
  overflow: hidden;
}

.formTarjetac {
  float: left;
  width: 100%;
  padding: 0 10px;
  margin-top: 8px;
}

.mostrar-menu {
  right: 15vw;

  @include media(xs) {
    right: 0;
  }
}

body {
  position: relative;
  right: 0;
  transition: right .4s;
}


/*=============================================
=            Modal producto            =
=============================================*/

#area__carrito {
  position: fixed;
  background: #fff;
  box-shadow: 0 0px 100px 0 rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  height: 450px;
  width: 80%;
  max-width: 780px;
  margin: auto;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  border: 1px solid #d3e0e9;
  z-index: 200;
  animation: 0.2s baaparecer;

    @include media(xs) {
      width: 100%;
      height: calc(100% - 5%);
      bottom: -75px;
    }
}

 .icovarout{
    background: url('img/circle-outline.svg') no-repeat center;
    background-size: auto 80%;

    @include media(xs) {
      background-size: auto 60%;
    }
  }

 .icovarcheck{
    background: url('img/circle-check.svg') no-repeat center;
    background-size: auto 80%;

    @include media(xs) {
      background-size: auto 60%;
    }
  }

.table_variacion{
  
label{
  font-size: 0.9em;
  color:#ff643e;
}

input[type=checkbox]{
  display:none; 
}

input[type=checkbox] + label{
  cursor:pointer;
}

label:before{
  content:'';
  background:transparent;
  border: 2px solid #ff643e;
  border-radius: 25px;
  display: inline-block;
  height: 25px;
  width: 25px;
  margin-right: 10px;
  text-align:center;
  vertical-align:middle;
  width:25px;
}
input[type=checkbox]:checked + label:before{
  content: '✔';
  font-size: 15px;
  font-family:'Times New Roman';
  color:white;
  background: #ff643e;
}

span{
   float: right;
   color:#ff643e;
   cursor:pointer;
}
  
}




#area__carrito.ng-leave {
  animation: 0.2s baocultar;
}

.container_detalle {
  float: left;
  width: 100%;
  height: calc(100% - 9%);
  overflow: auto;

    @include media(xs) {
      width: 100%;
    }

    &::-webkit-scrollbar {
      width: 8px;
      background-color: #ccc;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #FB4A38;
    }

    .cont_hedimg {
      height: 80%;
        @include media(xs){
          height: 250px;
        }
    }

    .imgproduto_detale {
      float: left;
      width: 100%;
      height: 100%;
      background: url('img/imgmc.png') no-repeat center;
      background-size: 100% auto !important;
        @include media(xs) {
          background-size: 100% auto !important;
        }
    }

    .productov_cont {
      height: 80%;
    }

    .precio_pro {
      float: left;
      width: 100%;
        p {
          float: left;
          font-size: 20px;
          font-weight: 400;
          color: #FB4A38;
          margin-bottom: 3px;
          margin-top: 10px;
        }
    }

     .variante_nombre {
      float: left;
      width: 100%;
        p {
          float: left;
          font-size: 12px;
          font-weight: 400;
          color: #FB4A38;
          margin-bottom: 3px;
          margin-top: 5px;
        }
    }




    .producto_descri {
      float: left;
      width: 100%;

        p {
          float: left;
          width: 100%;
          font-size: 15px;
          margin-bottom: 0;

            @include media(xs) {
              font-size: 13px;
            }
        }

        .container_vermenos, .container_vermas {
          float: left;
          width: 100%;

            a {
              float: left;
              width: 100%;
              margin-bottom: 15px;
            }
        }
    }

  .cant{
    font-size: 85%;
    margin-top: 25px;
    margin-bottom: 10px;
    background: white;
    height: 15px;
    width: 15px;
    text-align: center;
    border-radius: 100%;
    border: none;
    position: absolute;
   
  }

    .variaciones_container {
      float: left;
      width: 100%;
      height: 125px;
      overflow: auto;
      margin-top: 10px;

        &::-webkit-scrollbar {
          width: 2px;
          background-color: #ccc;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: #2942a9;
          background: linear-gradient(to right, #2942a9 0%, #002883 100%);
        }

        .table_variacion {
          float: left;
          width: 100%;
        }

        .btn_variacion {
          float: left;
          width: 100%;
          padding: 5px 0;
          border-bottom: 1px solid #ccc;

            p {
              float: left;
              width: 100%;
              font-size: 14px;
              font-weight: normal;
              text-align: left;
              margin: 0;
            }
        }
    }

    .footer_detallec {
      display: flex;
      margin-top: 10px;
      border-top: 1px solid #ccc;
      padding-top: 10px;
      flex-wrap: wrap;
      background: #fff;
      z-index: 100;

        @include media(xs){
          float: left;
          width: 100%;
          flex-direction: column-reverse;
          margin-top: 25px;
          margin-bottom: 25px;
        }

        .btn_ends {
          display: flex;
          align-items: flex-end;
        }
    }

    .container_cant {
      float: left;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;

        @include media(xs) {
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          margin-bottom: 20px;
          margin-top: 10px;
        }

        .contmasform {
          float: left;
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
            @include media(xs) {
              margin-bottom: 15px;
            }
        }

        .btn_menos  {

          position: relative;
          top:-8px;
          float: left;
          width: 30px;
          height: 30px;
          background: #ff643e; /* Old browsers */
          background: -moz-linear-gradient(left, #ff643e 0%, #fa4537 100%); /* FF3.6-15 */
          background: -webkit-linear-gradient(left, #ff643e 0%,#fa4537 100%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to right, #ff643e 0%,#fa4537 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff643e', endColorstr='#fa4537',GradientType=1 ); /* IE6-9 */
          border-radius: 5px;
          margin-right: 5px;
          cursor: pointer;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .input_forms {
          text-align: center;
          float: left;
          width: 60px;
          margin: 0 5px;
          padding: 10px;
          border-top: none;
          border-right: none;
          border-left: none;
          border-bottom: 1px solid #ddd;
          background: transparent;
          padding-left: 10px;
        }

        .btn_mas {
          position: relative;
          top:-8px;
          float: left;
          width: 30px;
          height: 30px;
          background: #ff643e; /* Old browsers */
          background: -moz-linear-gradient(left, #ff643e 0%, #fa4537 100%); /* FF3.6-15 */
          background: -webkit-linear-gradient(left, #ff643e 0%,#fa4537 100%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to right, #ff643e 0%,#fa4537 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff643e', endColorstr='#fa4537',GradientType=1 ); /* IE6-9 */
          border-radius: 5px;
          margin-right: 5px;
          cursor: pointer;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .container_btnagregar {
          float: left;
          width: 170px;
          margin-top: 5px;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;

          .btncarrito {
            float: left;
            width: 165px;
            padding: 10px;
            border-radius: 5px;
            border: none !important;
            text-decoration: none;
             background: #2942a9;
              background: linear-gradient(to right, #2942a9 0%, #002883 100%);
              border:1px solid #2942a9;
              box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
            color: #fff;
            font-size: 17px;
            font-weight: normal;
            text-align: center;
            cursor: pointer;

              @include media(xs) {
                font-size: 15px;
                padding: 8px;
              }
          }

          .btnacarrito {
            float: left;
            width: 165px;
            padding: 10px;
            border-radius: 5px;
            text-decoration: none;
            background: #fff;
            color: #333;
            border: 1px solid #ddd;
            box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
            font-size: 17px;
            font-weight: normal;
            text-align: center;
            cursor: pointer;

              @include media(xs) {
                font-size: 15px;
                padding: 8px;
              }
          }
        }
    }
}

.h-100 {
  height: 100%;

  @include media(xs) {
    height: auto;
  }
}

.mtcompartir {
  margin-top: 40px;
}

/*=====  End of Modal producto  ======*/

.container__menuphone {
  float: left;
  width: 100%;

    .menul {
      float: left;
      padding: 0;
      list-style: none;

      @include media(xs) {
        .item__menul {
          float: left;
          width: 100%;
          font-size: 13px;
          font-weight: 400;
          padding: 7px 18px;
          border-radius: 0;
          border-bottom: 1px solid #ddd;
        }
      }
    }
}

#area__menup {
  position: fixed;
  background: #fff;
  box-shadow: 0 0px 100px 0 rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  height: 450px;
  width: 60%;
  margin: auto;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  border: 1px solid #d3e0e9;
  z-index: 200;
  animation: 0.2s baaparecer;

    @include media(xs) {
      width: 90%;
      height: 300px;
    }
}

#area__menup.ng-leave {
  animation: 0.2s baocultar;
}

.container_menuphonelist {
  float: left;
  width: 100%;
  height: calc(100% - 17%);
  overflow: auto;

    @include media(xs) {
      width: 100%;
    }

    &::-webkit-scrollbar {
      width: 8px;
      background-color: #ccc;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #FB4A38;
    }
}


@media (min-width: 1200px) {
  .mw-tienda {
    width: 1000px !important;
  }
}

@media (max-width: 768px) {
  .dnone_phone {
    display: none !important;
  }

  .h-phone {
    height: 200px;
  }

  .sp {
    padding: 0 !important;
  }
}

/* Animaciones */
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fast {
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

.banner_logol {
  float: left;
  width: 130px;
  height: 130px;
  box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.12);
  border: 10px solid #fff;
  position: fixed;
  top: 85px;
  display: none;

  @include media(xs) {
    display: none !important;
  }

  a{
      width: 80px;
  }
}

.mbformc {
  margin-bottom: 5px !important;

  input {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.wcient_btn {
  width: 100% !important;
}

.container_messagestock {
  float: left;
  width: 100%;
  margin-top: 10px;
}
