/**
  EDgrid es una librería CSS para construir layouts con Responsive Web Design
  Importe este archivo en su proyecto para empezar a usarlo
**/

//Funciones para convertir px a em y rem
@import "components/functions";

// Variables globales (puede sobreescribirlas antes de importar ED grid)
// No las sobreescriba aquí, sino desde su proyecto.
  $cssCore    : true  !default; //Compila todo el css (no recomendado si se trabaja con sass).
  $cssHelpers : true  !default; //Ayudas extras para el layout (para alinear elementos, limpiar los floats, controlar el padding, etc...) Se recomienda activarlo.
  $devMode    : false !default; //Ayuda visual que nos permite ver en el navegador breakpoints, el tamaño de los elementos, el area disponible etc...

// Selectores (clases) para flex-container y flex-item

  $container : ed-container !default; // Permite modificar el nombre de las clases usadas como contenedor e item.
  $item      : ed-item !default;

// Ancho máximo para los contenedores
  $max-width : 1200px !default;

// Seperación horizontal entre items (*2)
  $gutter : 1rem !default;

// Breakpoints
  $breakpoints : (
    s  : 0,
    m  : 640px,
    l  : 1024px,
    xl : 1440px
  ) !default;

// Fracciones para calcular anchos de los items
  $fractions : 3 6 !default;

// Importar componentes
@import "components/base";            // estilos base
@import "components/media-queries";   // mixins from(), to(), fromTo()
@import "components/helpers";         // utilidades
@import "components/dev";             // modo dev
@import "components/core";            // core de EDgrid (containers y items)
@import "components/grid";            // mixin grid()
@import "components/visibility";      // mixins para visibilidad
@import "components/video";           // video RWD
@import "components/ed-menu";         // estilos para menus
@import "components/flexbox";         // utilidades con flexbox
@import "components/offset";          // offsets
@import "components/buttons";         // mixin button()
@import "components/css-core";        // componentes que se compilaran por default a css
@import "components/css-helpers";     // ayudas en css (por medio de clases)
@import "components/grid-v3";     // ayudas en css (por medio de clases)
