.container_registroem {
    float: left;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.container_registro {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    margin-bottom: 2rem;
}

.proceso_container {
    float: left;
    width: 100%;
    margin-top: 15px;
    position: absolute;
    top: -60px;
    z-index: 1000;

    ul {
        float: left;
        width: 100%;
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        @include media(xs) {
            justify-content: flex-end;
        }

        li {
            float: left;
            width: 25px;
            height: 25px;
            border-radius: 100%;
            color: #2942a9;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 10px;
            font-weight: bold;
        }
    }

    .activPaso {
        border: 2px solid #fe4437;
    }
}

.inicio_registro {
    float: left;
    width: 100%;
    margin-top: 15px;

    @include media(xs) {
        padding: 0 15px;
    }

    .info_registro {
        float: left;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        h2 {
            float: left;
            width: 100%;
            text-align: center;
            font-size: 25px;
            font-weight: 300;
            color: #2942a9;
            margin-bottom: 10px;
        }

        p {
            float: left;
            width: 100%;
            font-size: 15px;
            text-align: center;
            color: #aaa;
        }
    }

    .container_optionselec {
        float: left;
        width: 100%;
        margin-top: 10px;

            .form_select {
                float: left;
                width: 100%;

                .flex_containerform {
                    float: left;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    margin-bottom: 15px;

                        label {
                            float: left;
                            width: 35%;
                            color: #000;
                            margin-bottom: 5px !important;
                            font-size: 15px;

                            @include media(xs) {
                                width: 100%;
                                padding-left: 10px;
                            }
                        }

                        input {
                            background: #fff;
                            color: #2942a9;
                            box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.08) !important;
                            font-size: 16px;
                            border-radius: 10px;
                            font-weight: 500;
                            padding: 10px;
                            letter-spacing: 0.03em;
                              border: 1px solid #ddd !important;
                        }
                }

                p {
                    float: left;
                    width: 35%;
                    color: #000;
                    margin-bottom: 5px;

                    @include media(xs) {
                        width: 100%
                    }
                }

                .width_register {
                    width: 35% !important;

                    @include media(xs) {
                        width: 100% !important;
                    }
                }
            }
    }
}

.width_register.btn-group > .btn:first-child {
    border: 1px solid #ddd !important;
    background: #fff;
    color: #2942a9;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
    padding: 8px 10px;
}

.btn_regiscontainer {
    float: left;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0;

    @include media(xs) {
        margin: 0;
        margin-top: 15px;
    }

    .btn_register {
        float: left;
        width: 25%;
        background: linear-gradient(to right, #2942a9 0%, #002883 100%);
        color: #fff;
        font-size: 16px;
        border-radius: 10px;
        text-transform: uppercase;
        font-weight: 500;
        padding: 10px;
        letter-spacing: 0.03em;

        @include media(xs) {
            width: 65%;
            margin-bottom: 20px;
        }

        &:active {
            background: linear-gradient(to right, #2942a9 0%, #002883 100%);
        }

        &:focus {
            outline: none;
            color: #fff;
        }

        &:hover {
            color: #fff;
        }
    }
}

.cardr_container {
    float: left;
    width: 100%;
    margin-bottom: 20px;

    @include grid('.cardr_item', 1 2 4 ,10px);

    .cardr_item {
        float: left;
        width: 100%;
        background: #fff;
        border-radius: 10px;
        border: 1px solid #eee;
        box-shadow: 0 0 15px 0px rgba(0,0,0,0.08);
        padding: 5px 0;
        cursor: pointer;
        transition: all .3s ease;

        .ico_checkplan {
            position: relative;
            right: 0;
            top: 0;
            width: 50px;
            height: 25px;
            background: url('img/ico-checkplan.svg') no-repeat center;
            background-size: 50%;
            float: right;
        }

        h2 {
            font-size: 20px;
            color: #333;
            font-weight: bold;
            float: left;
            width: 100%;
            text-align: center;
            margin: 0;
            margin-bottom: 10px;
            margin-top: 15px;
        }

          h3 {
            font-size: 20px;
            color: #fe4437;
            font-weight: bold;
            float: left;
            width: 100%;
            text-align: center;
            margin: 0;
            margin-bottom: 15px;
            margin-top: 5px;
            small{
                    font-size: 14px;
                    color: #fe4437;
                    float: left;
                    width: 100%;
                    margin-top: 5px;
                      text-align: center;
            }
        }

        .cardr_img {
            float: left;
            width: 100%;
            height: 150px;
        }

        .icor_startup {
            background: url('img/icor-startup.svg') no-repeat center;
            background-size: 50%;

            @include media(xs) {
                background-size: 35%;
            }
        }

        .icor_ecommerce {
            background: url('img/icor-ecomerce.svg') no-repeat center;
            background-size: 50%;

            @include media(xs) {
                background-size: 35%;
            }
        }

        .icor_profesional {
            background: url('img/icor-profesional.svg') no-repeat center;
            background-size: 50%;

            @include media(xs) {
                background-size: 35%;
            }
        }

        .icor_premium {
            background: url('img/icor-ecomerce.svg') no-repeat center;
            background-size: 50%;

            @include media(xs) {
                background-size: 35%;
            }
        }

        .cardr_price {
            float: left;
            width: 100%;
            margin-top: 5px;

            p {
                float: left;
                width: 100%;
                font-size: 30px;
                text-align: center;
                font-weight: bold;
                color: #002883;
                margin: 0;

                small{
                    font-size: 20px;
                }
            }
        }

        .cardr_descripciont {
            float: left;
            width: 100%;
            margin-top: 0px;
            margin-bottom: 15px;


            p{
                float: left;
                width: 100%;
                font-size: 15px;
                font-weight: 400;
                color: #aaa;
                padding: 10px 15px;
                text-align: center;

            }

            ul {
                padding: 0;
                list-style: none;
                float: left;
                width: 100%;
                border-top: 1px solid #ddd;

                li {
                    border-bottom: 1px solid #ddd;
                    text-align: center;
                    font-size: 15px;
                    font-weight: 600;
                    color: #333;
                    text-align: center;
                    padding:7px 0;
                  
                }

                .confle{
                      background: url('img/icoindicacion.svg') no-repeat right 15px center;
                    background-size: 20px;

                    &:hover{
                          background: #002883 url('img/icoindicacion_b.svg') no-repeat right 15px center;
                    background-size: 20px;
                    border-bottom: none;
                    }
                }

                .conflei{
                    background: url('img/icoindicacioni.svg') no-repeat 15px center;
                    background-size: 20px;

                    &:hover{
                          background: #002883 url('img/icoindicacioni_b.svg') no-repeat 15px center;
                    background-size: 20px;
                    border-bottom: none;
                    }
                }
            }
        }
    }

    .cardr_sugerida {
        background: linear-gradient(to right, #f5f8ff 0%, #f0f0f0 100%);
        transform: scale(1.05,1.05);
    }
}

.center_busqueda {
    float: left;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.mbottomcards {
    margin-bottom: 25px;
}

.container_infoPlan {
    float: left;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
        float: left;
        width: 100%;
        text-align: center;
        font-size: 18px;
    }
}

.descripcion_li{
    float: left;
    width: 100%;
    margin:5px 0;

    ul{
        float: left;
        width: 100%;
        padding: 0;
        margin-right: 0;
        min-height: 260px;
    }

    li{
        float: left;
        list-style: none;
        width: 100%;
        padding: 4px 10px !important;
        padding-left: 30px !important;
        background: url('img/ico-checkr.svg') no-repeat 5px center;
        background-size: 16px;
        color: #555 !important;
        font-size: 12px !important;
        font-weight: normal !important;
        text-align: left !important; 
    }
}

.munuOptionlist {
    li {
        &:hover {
            background: #002883;
            color: #fff !important;
        }
    }
}

.footerbg_gris {
    background: #F4F7FD !important;
}


/*=============================================
=            Estilos para registro de Empresa            =
=============================================*/

.ingresoTarjeta {
    float: left;
    width: 100%;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @include media(xs) {
        padding: 0 15px;
    }

    .info_registro {
        float: left;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        h2 {
            float: left;
            width: 100%;
            text-align: center;
            font-size: 25px;
            font-weight: 300;
            color: #2942a9;
            margin-bottom: 10px;
        }

        p {
            float: left;
            width: 100%;
            font-size: 15px;
            text-align: center;
            color: #aaa;
        }
    }

    .containerplan {
        float: left;
        width: 100%;
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 20px;

        @include media(xs) {
            padding: 0 15px;
        }

        p {
            float: left;
            width: 35%;
            color: #000;
            margin-bottom: 5px !important;
            font-size: 15px;
            @include media(xs) {
                width: 100%;
            }
        }

        .box_palanselec {
            width: 35%;
            background: #fff;
            color: #2942a9;
            box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.08);
            font-size: 16px;
            font-weight: bold;
            border-radius: 10px;
            padding: 10px;
            letter-spacing: 0.03em;
            border: 1px solid #ddd;

            @include media(xs) {
                width: 100%;
            }
        }
    }

    .form_phone {
        @include media(xs) {
            float: left;
            width: 100%;

            .form-group {
                float: left;
                width: 100%;
            }
        }
    }

    .flex_btn {
        float: left;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .wd_btntarjeta {
            width: 65%;
        }
    }
}

.inicio_registroempre {
    float: left;
    width: 100%;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @include media(xs) {
        padding: 0 15px;
    }

    .info_registro {
        float: left;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        h2 {
            float: left;
            width: 100%;
            text-align: center;
            font-size: 25px;
            font-weight: 300;
            color: #2942a9;
            margin-bottom: 10px;
        }

        p {
            float: left;
            width: 100%;
            font-size: 15px;
            text-align: center;
            color: #aaa;
        }
    }

    .input_box {
        input {
            background: #fff;
            color: #2942a9;
            box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.08) !important;
            font-size: 16px;
            border-radius: 10px;
            font-weight: 500;
            padding: 10px;
            letter-spacing: 0.03em;
            border: 1px solid #ddd !important;
            margin-top: 5px !important;
        }

        textarea{
            background: #fff;
            color: #2942a9;
            box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.08) !important;
            font-size: 16px;
            border-radius: 10px;
            font-weight: 500;
            padding: 10px;
            letter-spacing: 0.03em;
            border: 1px solid #ddd !important;
        }
    }
}

.logo_pci{
        float: left;
    width: 120px;
    height: 70px;
    background: url('img/logopci.png') no-repeat center;
    background-size: 100%;
}

.logo_cybs{
    float: left;
    width: 120px;
    height: 70px;
    background: url('img/logocybersource.png') no-repeat center;
    background-size: 100%;
}

.logo_visa{
        float: left;
    width: 120px;
    height: 70px;
    background: url('img/logovisa.png') no-repeat center;
    background-size: 100%;
}

.logo_master{
        float: left;
    width: 120px;
    height: 70px;
    background: url('img/logomaster.png') no-repeat center;
    background-size: 100%;
}

.espac{
    margin:5px 20px;
}

.foo_regis{
    padding-top: 5px !important;
    float: left;
    p{
        margin:15px 0 !important;
        color: #999 !important;
    }
}