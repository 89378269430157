.icovescritorio{
  background:  url('img/icov/icov-escritorio.svg') no-repeat 15px center !important;
  background-size: 18px !important;
}

.icovtransacciones{
  background:  url('img/icov/icov-transacciones.svg') no-repeat 15px center !important;
  background-size: 18px !important;
}

.icovposvirtual{
  background:  url('img/icov/icov-posvirtual.svg') no-repeat 15px center !important;
  background-size: 18px !important;
}

.icovsolicitud{
  background:  url('img/icov/icov-solicitud.svg') no-repeat 15px center !important;
  background-size: 18px !important;
}

.icovtienda{
  background:  url('img/icov/icov-tienda.svg') no-repeat 15px center !important;
  background-size: 18px !important;
}

.icovclientes{
  background:  url('img/icov/icov-clientes.svg') no-repeat 15px center !important;
  background-size: 18px !important;
}

.icovdeveloper{
  background:  url('img/icov/icov-developer.svg') no-repeat 15px center !important;
  background-size: 18px !important;
}

.icovajustes{
  background:  url('img/icov/icov-ajustes.svg') no-repeat 15px center !important;
  background-size: 18px !important;
}


.icovpreguntas{
  background:  url('img/icov/icov-preguntas.svg') no-repeat 15px center !important;
  background-size: 18px !important;
}