.caja_modal{
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.6);
  z-index: 200;
  top:0;
  left: 0;
}

.caja_cargando2{
  position: absolute;
  width: 300px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 250px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0px 100px 0 rgba(0, 0, 0, 0.3);
  z-index: 500;

  .cargador{
    float: left;
    width: 100%;
    height: 150px;
    background: url('img/cargadortran.gif') no-repeat center;
    background-size: 75px;
  }

  .tranacep{
     float: left;
     width: 100%;
     height: 80px;
     background: url('img/ico-aceptada.svg') no-repeat center;
     background-size: 60px;
  }

  .tranerror{
     float: left;
     width: 100%;
     height: 80px;
     background: url('img/ico-denegada.svg') no-repeat center;
     background-size: 50px;
  }

  p{
    text-align: center;
    float: left;
    width: 100%;
    margin-bottom: 0;
  }
}

.caja_resultado{
  position: absolute;
  width: 350px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 400px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0px 100px 0 rgba(0, 0, 0, 0.3);
  z-index: 500;

  .cargador{
    float: left;
    width: 100%;
    height: 150px;
    background: url('img/cargadortran.gif') no-repeat center;
    background-size: 75px;
  }

  .tranacep{
     float: left;
     width: 100%;
     height: 80px;
     background: url('img/ico-aceptada.svg') no-repeat center;
     background-size: 60px;
  }

  .tranerror{
     float: left;
     width: 100%;
     height: 80px;
     background: url('img/ico-denegada.svg') no-repeat center;
     background-size: 50px;
  }

  p{
    text-align: center;
    float: left;
    width: 100%;
    margin-bottom: 0;
  }
}