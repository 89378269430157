@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600");
@import url("https://fonts.googleapis.com/css?family=Inconsolata:400,700");

:root {
   --title: #{$title};
   --sub_title: #{$sub-title};
   --text: #{$big-text};
   --sub_text: #{$text};
   --small_text: #{$text - 1};
   --small_span: #{$text - 3};

   @include to(m) {
      --title: 18px;
      --sub_title: 16px;
      --text: 14px;
      --sub_text: 13px;
      --small_text: 12px;
      --small_span: 10px;
   }
}
