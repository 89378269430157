:root {
   --bg-rojo: linear-gradient(to right, #ff6e64 0%, #ff9951 100%);
   --bg-azul: linear-gradient(to right, #5477f0 0%, #6c64ee 100%);

   --blanco: #fff;
   --gris: #e8e8e8;
   --grisc: #f9f9f9;
   --negro: #454545;
   --naranja: #ffa04e;
   --melon: #ff6567;
   --morado: #7963f1;
   --grisos: #999;
   --rojo: #f96c55;
   --verde: #6ccf25;
   --azul: #1473e6;
}
