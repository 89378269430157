
// Body
$body-bg: #f5f8fa;
$principal:#fff;
$fontprincipal: 'Source Sans Pro', sans-serif;
$grisclaro:#f9f9f9;
$grisclaro2:#ddd;



// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #3097D1;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;
$azul: #2942a9;

// Typography
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-sans-serif: "Raleway", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #636b6f;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;

$text_bold:700;
$extra_bold: bold;
$text_medium: 500;
$text_regular: 400;
$text_ligth: 300;

$title: 40px;
$sub_title: 25px;
$text: 18px;
$sub_text: 15px;

//Tamaños de fuentes
$big-title: 20px;
$title: 18px;
$bigsub-title: 17px;
$sub-title: 16px;
$big-text: 15px;
$text: 14px;

