#area_tarjetas {
 position: fixed;
 background: #fff;
 -webkit-box-shadow: 1px 0px 2px 0px rgba(0, 0, 0, 0.15);
 box-shadow: 1px 0px 2px 0px rgba(0, 0, 0, 0.15);
 height: 100%;
 width: 360px;
 right: 0;
 top: 0;
 z-index: 200;
 animation: .3s tarmostrar;
 overflow-y:auto;

  .head_Cards {
   float: left;
   width: 100%;
   padding: 13px;
   background: #ff643e;
   background: linear-gradient(to right, #ff643e 0%,#fa4537 100%);

     p {
       float: left;
       width: 75%;
       margin: 0;
       font-size: 18px;
       font-weight: normal;
       text-align: left;
       color: #fff;
     }

     .icocerrarc {
       float: left;
       width: 25%;
       height: 25px;
       background: url('img/ico-cerrarc.svg') no-repeat right center;
       background-size: 20px;
       cursor: pointer;
     }
 }


  .full button span {
    background-color: green;
    border-radius: 32px;
    color: black;
  }

  .partially button span {
    background-color: orange;
    border-radius: 32px;
    color: black;
  }

  .body_Cards {
    float: left;
    width: 100%;
    padding: 0 10px;

    .form_Tarjeta {
      background: #F0F0F0;

      p {
        text-align: left;
        font-size: 15px;
        color: #000;
        margin-bottom: 2px;
      }

      .form-group {
        margin-bottom: 5px;
      }

      .btn_card {
        background: #fff;
        margin-right: 5px;

        &:hover {
          font-weight: bold;
        }

        &:focus {
          outline: none;
          border: none;
        }
      }

      .flex_end {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

.card_itemEnd {
  display: flex;
  align-items: flex-end;
}

@keyframes tarocultar {
 from {
   right: 0;
 }
 to {
   right: -30%;
 }
}

@keyframes tarmostrar {
 from {
   right: -360px;
 }
 to {
   right: 0;
 }
}

#area_tarjetas.ng-leave {
 animation: .3s tarocultar;
}

.btn_nuevaTarjeta {
  float: left;
  width: 100%;
  height: 50px !important;
  border: 2px dashed #bbb !important;
  margin-bottom: 0;
  background: #F0F0F0;
}

.icopagos{
  padding-left: 30px !important;
  background: url('img/icopago.svg') no-repeat 5px center;
  background-size: 17px;
}

.icocancelar{
  padding-left: 30px !important;
  background: url('img/icocancelars.svg') no-repeat 5px center;
  background-size: 17px;
}

.icoeditars {
  padding-left: 30px !important;
  background: url('./img/icoeditar.svg') no-repeat 5px center;
  background-size: 17px;
}

// Sección pagos realizados
#area_pagos {
  position: fixed;
  background: #fff;
  -webkit-box-shadow: 1px 0px 2px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 1px 0px 2px 0px rgba(0, 0, 0, 0.15);
  height: 100%;
  width: 100%;
  right: 0;
  top: 0;
  z-index: 200;
  animation: .3s pagosmostrar;
  overflow-y:auto;

  .head_pagos {
   float: left;
   width: 100%;
   padding: 13px;
   background: #F0F0F0;

     p {
       float: left;
       width: 75%;
       margin: 0;
       font-size: 18px;
       font-weight: normal;
       text-align: left;
       color: #000;
     }

     .icocerrarc {
       float: left;
       width: 25%;
       height: 25px;
       background: url('img/ico-exit.svg') no-repeat right center;
       background-size: 20px;
       cursor: pointer;
     }
  }
}


.infovalida{
  float: left;
  width: 100%;
  margin:10px 0;
  background: #eee;
  border-radius: 5px;
  padding:10px 15px;

  p{
    color: #333;
    font-size: 14px;
    float: left;
    width: 100%;
    margin:0;
  }
}

.espevalida{
  float: left;
  width: 100%;
  margin:25px 0;

    .icoespera{
    float: left;
    width: 100%;
    height: 90px;
    background: url('img/cargadortran.gif') no-repeat center;
    background-size: 80px;
  }

  h3{
    font-size: 25px;
    float: left;
    width: 100%;
    text-align: center;
    margin:5px 0;
  }

}

@keyframes pagosocultar {
 from {
   right: 0;
 }
 to {
   right: -100%;
 }
}

@keyframes pagosmostrar {
 from {
   right: -100%;
 }
 to {
   right: 0;
 }
}

#area_pagos.ng-leave {
 animation: .3s pagosocultar;
}

.max_htable {
  height: 450px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 5px;
    background-color: #ccc;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #FB4A38;
  }
}

.hcaja_help {
  height: 210px;
}

.btn_cancelar {
  float: left;
  width: 100%;
  padding: 10px !important;
  border-radius: 5px !important;
  border: 1px solid #ddd !important;
  background: #fff;
  color: #000;
  font-size: 17px;
  font-weight: normal;

  &:focus {
    outline: none !important;
    border: 1px solid #ddd !important;
  }
}

.pl_n {
  padding-left: 5px !important;
}
