// variables:
$breakpoint-xs: 767px;
$breakpoint-sm: 767px;
$breakpoint-md: 1023px;
$breakpoint-lg: 1360px;

@mixin media($media) {
  @if $media == xs {
    @media (max-width: $breakpoint-sm) { @content; }
  }
  @else if $media == sm {
    @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) { @content; }
  }
  @else if $media == md {
    @media (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) { @content; }
  }
  @else if $media == lg {
    @media (min-width: $breakpoint-lg) { @content; }
  }
}