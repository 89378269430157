.boxdata_container {
    float: left;
    width: 100%;
    margin-top: 25px;
    margin-bottom: 25px;
}

.boxdata_cardcontainer {
    float: left;
    width: 100%;
    padding: 0;

    @include grid('.boxdata_item', 1 2 4 4);

    .boxdata_item {
        float: left;
        width: 100%;
        border-radius: 10px;
        border: 1px solid #eee;
        box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.08);
        background: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;

        .boxdata_head {
            float: left;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .boxdata_title {
                font-size: 15px;
                text-transform: uppercase;
                color: #333;
                font-weight: bold;
                background: none !important;
                box-shadow: none !important;
            }

            .boxdata_gtq {
                font-size: 25px;
                font-weight: bold;
                text-transform: uppercase;
                margin-top: 5px;
                margin-bottom: 0;
                color: $azul;

                sup {
                    font-size: 15px;
                }
            }

            .boxdata_uds {
                margin-top: 5px;
                font-size: 15px;
                color: #333;

                sup {
                    font-size: 10px;
                }
            }
        }

        .border_data {
            border-top: 1px solid #eee;
            margin-top: 15px;

            .border_datar {
                border-right: 1px solid #eee;
            }

            .boxdata_flex {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                padding-top: 15px;

                .boxdata_gtq {
                    font-size: 18px;
                    font-weight: bold;
                    text-transform: uppercase;
                    margin-top: 5px;
                    margin-bottom: 0;
                    color: $azul;
                    margin: 0;

                    sup {
                        font-size: 10px;
                    }
                }

                .boxdata_usd {
                    font-size: 13px;
                    color: #333;
                    margin-top: 5px;
                    margin-bottom: 0;
                    font-weight: bold;
                    text-transform: uppercase;

                    sup {
                        font-size: 10px;
                    }
                }

                .boxdata_titlefoo {
                    font-size: 15px;
                    color: #333;
                    margin-top: 5px;
                    margin-bottom: 10px;
                    font-weight: bold;
                }
            }
        }
    }
}

.areacolo{
    margin:5px 0;
    background: #ff643e;
    background: linear-gradient(to right, #ff643e 0%, #fa4537 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff643e', endColorstr='#fa4537',GradientType=1 );
    border-radius: 4px;
    box-shadow: 0 7px 14px 0 rgba(50,50,93,.1), 0 3px 6px 0 rgba(0,0,0,.07);


    .highcharts-background{
      background: rgba(255,255,255,0) !important;
      fill:  rgba(255,255,255,0) !important;
      filter:0 !important;
    }
    .highcharts-area{
          fill: rgba(255,255,255,0.6) !important;
    }


    .highcharts-grid-line{
      stroke: rgba(255,255,255,0.2) !important;
    }


    .highcharts-graph{
      stroke: rgba(255,255,255,1) !important;
    }

    .highcharts-axis-title{
          tspan{
            color: #fff;
             fill: #fff;
          }
    }

    .highcharts-axis-labels{
        text {
            color: #fff !important;
            fill: #fff !important;

            tspan{
                color: #fff;
                fill: #fff;
            }
        }
    }

    h3{
      float: left;
      color:#fff;
      font-weight: normal;
      margin:15px 0;
      font-size: 17px;
      text-align: left;
      width: auto;
    }

     h2{
      float: right;
      color:#fff;
      font-weight: normal;
      margin:15px 0;
      font-size: 17px;
      text-align: left;
    }

    .adicio{
      border-top: 1px solid rgba(255,255,255,0.5);
      float: left;
      width: 100%;
      color:#fff;
      font-weight: normal;
      padding-top: 5px;
      margin:10px 0 10px;
      font-size: 17px;
      text-align: center;
    }

}