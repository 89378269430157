.container_flexcenter {
    float: left;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.form_posc {
    float: left;
    width: 100%;
}

.btnbox_center {
    float: left;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container_btnoption {
    float: left;
    width: 80%;
    display: flex;
    justify-content: space-around;
    align-items: center;

    @include media(xs) {
        width: 100%;
    }
}

.btn_formpos {
    float: left;
    width: 150px;
    background: #fff;
    border: 1px solid #eee !important;
    text-transform: uppercase;
    font-weight: bold !important;
    font-size: 15px !important;
    letter-spacing: 0.5px;
    color: #2942A9;
    box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.08);

    &:active {
        box-shadow: none !important;
        outline: none !important;
    }

    &:focus {
        color: #2942A9 !important;
        outline: none !important;
    }
}

.btn_buspos {
    position: absolute;
    top: 11px;
    right: 9px;
    height: 25px;
    width: 43px;
    border: none !important;
    box-shadow: none !important;

    &:active {
        box-shadow: none !important;
        outline: none !important;
    }

    &:focus {
        color: #2942A9 !important;
        outline: none !important;
    }
}

.hover_btn {
    &:hover {
        background: #2942a9;
        color: #fff !important;
    }
}

.btn_formagregar {
    float: left;
    width: 150px;
    background: #2942A9;
    border: none !important;
    text-transform: uppercase;
    font-weight: 300 !important;
    font-size: 15px !important;
    letter-spacing: 0.5px;
    color: #fff;
    box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.08);

    &:hover {
        color: #fff !important;
    }

    &:active {
        box-shadow: none !important;
        outline: none !important;
    }

    &:focus {
        color: #2942A9 !important;
        outline: none !important;
    }
}

.ico_lupa {
    background: #fff url('img/ico-buscar.svg') no-repeat 15px center !important;
    background-size: 20px !important;
}

.ico_lupabus {
    background: url('img/ico-buscar.svg') no-repeat 15px center !important;
    background-size: 20px !important;
}

.ico_agregarcliente {
    background: #fff url('img/ico-nuevo.svg') no-repeat 15px center !important;
    background-size: 20px !important;
}

.ico_agregarclienteb {
    background: #2942A9 url('img/ico-nuevo.svg') no-repeat 15px center !important;
    background-size: 20px !important;
}

.ico_bproducto {
    background: #fff url('img/ico-buscar.svg') no-repeat 15px center !important;
    background-size: 20px !important;
    width: 165px;

    @include media(xs) {
        width: 150px;
        background: #fff url('img/ico-buscar.svg') no-repeat 10px center !important;
        background-size: 20px !important;
    }
}

.ico_mailsoli {
  background: #fff url('img/ico-mailazul.svg') no-repeat 15px center !important;
  background-size: 20px !important;

  @include media(xs) {
    width: 100%;
    background: #fff url('img/ico-mailazul.svg') no-repeat 90px center !important;
    background-size: 20px !important;
  }

  &:hover {
    background: #2942a9 url('img/icosolicitud_b.svg') no-repeat 15px center !important;
    background-size: 20px !important;
    color: #fff !important;
  }
}

.ico_whatsappsoli {
  background: #fff url('img/ico-whatsazul.svg') no-repeat 8px center !important;
  background-size: 20px !important;

  @include media(xs) {
    width: 100%;
    background: #fff url('img/ico-whatsazul.svg') no-repeat 75px center !important;
    background-size: 20px !important;
  }

  &:hover {
    background: #2942a9 url('img/icowhat_b.svg') no-repeat 8px center !important;
    background-size: 20px !important;
    color: #fff !important;
  }
}

.ico_linksoli {
  background: #fff url('img/ico-linkazul.svg') no-repeat 15px center !important;
  background-size: 20px !important;

  @include media(xs) {
    width: 100%;
    background: #fff url('img/ico-linkazul.svg') no-repeat 90px center !important;
    background-size: 20px !important;
  }

  &:hover {
    background: #2942a9 url('img/ico-link_b.svg') no-repeat 15px center !important;
    background-size: 20px !important;
    color: #fff !important;
  }
}

.flexcolum {
    flex-direction: column;
}

.input_boxpos {

    input {
        background: #fff;
        color: #333;
        box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.03) !important;
        font-size: 15px;
        border-radius: 8px;
        font-weight: 500;
        padding: 7px 5px;
        letter-spacing: 0.03em;
        border: 1px solid #ddd !important;

        &:focus {
            background: #fff;
        }
    }

    textarea {
        background: #fff;
        color: #2942a9;
        box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.08) !important;
        font-size: 16px;
        border-radius: 10px;
        font-weight: 500;
        padding: 5px;
        letter-spacing: 0.03em;
        border: 1px solid #ddd !important;

        &:focus {
            background: #fff;
        }
    }
}

.nya-bs-select.list_width {
    .dropdown-menu {
        float: left !important;
        width: 100% !important;
    }

    .bs-searchbox, .bs-actionsbox {
        float: left;
        width: 100%;
    }
}

.datos_cliente {
    p {
        float: left;
        width: 100%;
        font-weight: normal;
        color: #2942A9;
        font-size: 15p;
        margin-bottom: 0;
    }
}

.ico_flechamas {
    float: left;
    width: 50px;
    height: 25px;
    background: url('img/ico-masp.svg') no-repeat center;
    background-size: 30%;
    cursor: pointer;
    transition: all .2s ease;
}

.h_cliente {
    height: 50px;
    transition: height .2s ease;
}

.h_editarcliente {
    height: 330px;
    transition: height .2s ease;

    @include media(xs) {
        height: 520px;
    }
}

.rotate_flecha {
    transition: all .2s ease;
    transform: rotate(180deg);
}

.margin_rigth {
    margin-right: 15px;
}

.container_price {
    display: flex;
    justify-content: flex-end;

    @include media(xs) {
        justify-content: center;
        margin-top: 15px;
    }

    p {
        float: left;
        margin: 0;
        font-size: 20px;
        font-weight: bold;
        color: #000;
    }
}

.list_productos {
    float: left;
    width: 100%;
    margin-bottom: 15px;

    .ico_deletep {
        float: left;
        width: 100%;
        height: 25px;
        border-radius: 5px;
        border: 1px solid #eee;
        box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.08);
        background: #fff url('img/ico-eliminarc.svg') no-repeat center;
        background-size: 50%;
        cursor: pointer;
    }

    .ico_masp {
        float: left;
        width: 50%;
        height: 25px;
        border-radius: 5px;
        border: 1px solid #eee;
        box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.08);
        background: #fff url('img/ico-masp.svg') no-repeat center;
        background-size: 45%;
        cursor: pointer;
        transform: rotate(180deg);
    }

    .ico_menosp {
        float: left;
        width: 50%;
        height: 25px;
        border-radius: 5px;
        border: 1px solid #eee;
        box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.08);
        background: #fff url('img/ico-masp.svg') no-repeat center;
        background-size: 45%;
        cursor: pointer;
    }
}

.list_clientes {
    float: left;
    width: 100%;
    margin-bottom: 20px;
    border-top: 2px solid #eee;
    max-height: 225px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 2px;
      background-color: #ccc;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #2942a9;
      background: linear-gradient(to right, #2942a9 0%, #002883 100%);
    }

    .border_item {
        padding: 10px 0;
        border-bottom: 2px solid #eee;
    }

    .btn_scliente {
        float: left;
        width: 100%;
        background: #2942a9;
        color: #fff;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    p {
        font-size: 14px;
        color: #000;
        font-weight: 400;
        margin-bottom: 0;
    }
}

.btn_moneda {
    float: left;
    width: 100%;
    display: flex;
    justify-content: center;
    @include media(xs) {
        float: left;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
}

.sinmargen_tel {
    @include media(xs) {
        margin: 0 !important;
    }
}

.mbottom_tel {
    @include media(xs) {
        margin-bottom: 15px !important;
    }
}

.aling_btn {
    display: flex;
    justify-content: flex-start;

    @include media(xs) {
        justify-content: space-between;
    }
}

.containeroptionselect {
    @include hideFrom(l);
}

.optionselect_pos {
    float: left;
    width: 100%;
    border: 1px solid #ddd !important;
    background: #fff;
    color: #2942a9;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
    padding: 8px 10px;

    &:focus {
        outline: none !important;
    }

    @include hideFrom(l);
}

.font_celpos {
    @include media(xs) {
        font-size: 12px;
    }
}

.visible_phone {
    @include hideFrom(l)
}

.btn_pagarspos {
    float: left;
    width: 150px;
    background: linear-gradient(to right, #ff643e 0%, #fa4537 100%) !important;
    border: none !important;
    text-transform: uppercase;
    font-weight: bold !important;
    font-size: 15px !important;
    letter-spacing: 0.5px;
    color: #fff;
    box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.08);

    &:active {
        box-shadow: none !important;
        outline: none !important;
    }

    &:focus {
        color: #fff !important;
        outline: none !important;
    }

    &:hover {
        color: #fff !important;
    }
}

.width_btnpos {
    width: 100%;
}

.width_optionenviopos {
    @include media(xs) {
        width: 100%;
    }
}

.loading_pos{
    background: url('img/ico-loadingpos.svg') no-repeat 10px  center !important;
    background-size: 20px !important;
}

.btnselect_possol {
    background: linear-gradient(to right, #2942a9 0%, #002883 100%) !important;
    color: #fff;

    &:hover {
        color: #fff !important;
    }
}
