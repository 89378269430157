// Variables
@import "variables";
$cssCore    : false;
$cssHelpers : true;
$devMode    : false;

//Component mixin
@import "media-queries";

// Style Framework and Library
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "library/ed-grid/ed-grid";
// @import "node_modules/ed-grid/ed-grid";

//Components pagalo
@import "media-queries";
@import "components/buttons";
@import "components/loader";
@import "components/cardPago";

//Sytyle Theme
@import "tienda";
@import "personalizar";
@import "pagosrecu";
@import "solicitud-pago";
@import "modal-ayuda";
@import "registro";
@import "pos";
@import "cuentaspor-pagar";
@import "escritorio";
@import "menu";
@import "pagalo_style";
@import "pagolinea";

//variables css colors and fonts theming
@import "theme/colors";
@import "theme/fonts";

body, html {
  height: 100%;
}

.prin_app {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
}

.prin_app .container, .prin_app .container .row {
  height: 100%;
  position: relative;
}

.areacontenido{
  float: left;
    position: relative;
    min-height: 100%;
    background: #f9f9f9;
    top:50px;
}

.contepa{
  float: left;
  width: 100%;


   h1{
    color: #2942A9;
    background: #fff;
    font-size: 17px;
    float: left;
    /* width: 100%; */
    text-align: left;
    margin-bottom: 8px;
    margin-top: 8px;
    padding: 7px 15px;
    border-radius: 3px;
    box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
    font-weight: 600;
    letter-spacing: 0.01em;

   }
   h3{
    font-size: 16px;
    float: left;
    width: 100%;
    font-weight: normal;
    text-align: center;
    margin-bottom: 8px;
    margin-top: 8px;
   }
}
