.opciones_cabecera {
    float: left;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media(xs) {
        flex-wrap: wrap;
        // justify-content: center;
        margin-bottom: 15px;
    }
}

.btn_ayuda {
    background: linear-gradient(to right, #2942a9 0%, #002883 100%) !important;
    color: #fff;
    font-size: 14px !important;
    font-weight: 600 !important;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    margin-left: 5px;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.6;
    border-radius: 4px;
    user-select: none;

    &:hover {
        background: #fff !important;
        color: #2942A9 !important;
    }

    @include hideTo(m);
}

.btn_ayuda__phone {
    width: 40px;
    height: 40px;
    background: #2942a9 url('img/icoayuda.svg') no-repeat center !important;
    background-size: 50% !important;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.6;
    border-radius: 4px;
    user-select: none;

    &:hover {
        background: #2942a9 url('img/icoayuda.svg') no-repeat center !important;
        background-size: 50% !important;
    }

    @include hideFrom(l);
}

 #modalAyuda {
    position: fixed;
    background: #fff;
    box-shadow: 1px 0px 2px 0px rgba(0, 0, 0, 0.15);
    height: 100%;
    width: 375px;
    right: 0;
    top: 0;
    z-index: 200;
    animation: .3s craparecer;
    overflow-y: auto;

        @include media(xs) {
            width: 100%;
        }
 }

#modalAyuda.ng-leave {
    animation: .3s crocultar;

    @include media(xs) {
        animation: .1s crocultar;
    }
}

.head_ayuda {
    float: left;
    width: 100%;
    background: linear-gradient(to right, #2942a9 0%, #002883 100%);
    color: #fff;
    font-size: 18px;
    padding: 13px;

    p {
        float: left;
        width: 75%;
        margin: 0;
        font-size: 18px;
        font-weight: normal;
        text-align: center;
        color: #fff;
        padding-left: 85px;
    }

    .icocerrarc {
        float: left;
        width: 25%;
        height: 25px;
        background: url('img/ico-cerrarc.svg') no-repeat right center;
        background-size: 22px;
        cursor: pointer;
    }
}


.footer_ayuda {
    float: left;
    width: 100%;
    background: linear-gradient(to right, #2942a9 0%, #002883 100%);
    color: #fff;
    font-size: 18px;
    padding: 13px;

    p {
        float: left;
        width: 75%;
        margin: 0;
        font-size: 12px;
        font-weight: normal;
        text-align: center;
        color: #fff;
        padding-left: 85px;
    }

}


.ayuda_container {
    float: left;
    width: 100%;
    height: calc(100% - 15%);
    overflow: auto;

    &::-webkit-scrollbar {
        width: 5px;
        background-color: #ccc;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #2942a9;
        background: linear-gradient(to right, #2942a9 0%, #002883 100%);
    }

    .ayuda_tabs {
        display: flex;
        width: 100%;
        padding: 0;
        justify-content: space-between;

        .tab {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 5px;
            width: 50%;
            cursor: pointer;
        }

        .tab_faq {
            background: linear-gradient(to right, #f5f8ff 0%, #f0f0f0 100%);
            p {
                margin-bottom: 0;
            }
        }

        .tab_ticket {
            background: linear-gradient(to right, #f5f8ff 0%, #f0f0f0 100%);
            p {
                margin-bottom: 0;
            }
        }

        .tab_active {
            background: #ccc !important;
            color: #fff !important;
        }
    }

    .container_faq {
        float: left;
        width: 100%;
        padding: 0 15px;


        .pregunta_details {
            float: left;
            width: 100%;
            display: flex;
            background: #f9f9f9;
            border-radius: 5px;
            padding: 0 10px;
            margin-top: 10px;

            .title_details {
                padding: 10px 0;
                font-size: 15px;
                font-weight: bold;
                border-bottom: 1px solid #ddd;
                margin-bottom: 10px;

                &:focus {
                    outline: 0;
                }
            }

            p {
                font-size: 13px;
            }
        }
    }

    .container_ticket {
        float: left;
        width: 100%;
        padding: 0 15px;

        .form_tiket {
            float: left;
            width: 100%;
            padding: 0;
            margin-bottom: 20px;
        }

        .container_tickets_list {
            float: left;
            width: 100%;
            margin-top: 15px;
            border-top: 1px solid #ccc;

            h3 {
                font-size: 17px;
                font-weight: bold;
                color: #2942A9;
                margin-bottom: 10px;
            }

            .tickets_list_item {
                background: #fff;
                border-radius: 5px;
                box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.12);
                padding: 10px 5px;
                margin-bottom: 15px;

                p {
                    font-size: 13px;
                    margin-bottom: 2px;
                }

                span{
                    align-content: right;
                    margin-left: 275px;
                    background: #eee;
                    box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.12);
                    padding: 10px 5px;
                    margin-bottom: 15px;
                    border-radius: 5px;

                }
            }
        }
    }
}

.rnone {
    resize: none;
}