@mixin cardPago {
   float: left;
   width: 100%;
   background: #fff;

   form {
      float: left;
      width: 100%;

         label {
            color: #454545;
            font-weight: 400;
         }

         input {
            background: #fff !important;
            border-radius: 8px !important;
            border: 1px solid #454545 !important;
            color: #454545 !important;
            margin-bottom: 10px !important;
         }

         ol {
            button.btn-default {
               background: #fff !important;
               border-radius: 8px !important;
               border: 1px solid #454545 !important;
               color: #454545 !important;
            }
         }

         .btn_ayudacvv {
            float: left;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            cursor: pointer;
            display: flex;
            justify-content: center;
            background: $azul url('img/icoayuda.svg') no-repeat center center;
            background-size: 14px;
            position: relative;
            left: 0px;
         }
   }
}
