.mtbanner {
    margin-top: 75px;
}

.cont_frm {
    display: flex;
    justify-content: flex-start;
    padding: 15px;

    h4{
      font-size: 15px;
      font-weight: bold;
      margin: 25px 0 10px;

    }
}


.pinput {
    padding-left: 10px !important;
}

.dflexbtn {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.bnt_logp {
    display: flex;

    a {
        margin: auto;
        text-align: center;
        text-decoration: none;
        color: #000;
    }

    small {
        margin: auto;
        text-align: center;
        color: #000;
    }
}

.banner_item {
    display: flex;

    a {
        margin: auto;
        text-align: center;
        text-decoration: none;
        color: #000;
    }

    small {
        margin: auto;
        text-align: center;
        color: #000;
    }
}


.miportada{
  float: left;
  width: 100%;
  height: 100%;
}

.btnedipor{
  position: absolute;
  top:15px;
  left:15px;

     a{
      float: left;
      width: auto !important;
      background: rgba(0,0,0,0.5);
      color:#fff !important;
      font-weight: normal;
      font-size: 13px;
      padding: 5px 10px;
      border-radius: 5px;
      cursor: pointer;
          box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
                opacity: 0.7;

          &:hover{
            opacity: 1;
          }

     }
}

.btnedilogo{
  position: absolute;
  top:5px;
  left:10px;

     a{
      float: left;
      width: auto !important;
      background: rgba(0,0,0,0.5);
      color:#fff !important;
      font-weight: normal;
      font-size: 13px;
      padding: 5px 10px;
      border-radius: 5px;
      cursor: pointer;
          box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
          opacity: 0.7;

          &:hover{
            opacity: 1;
          }

     }
}