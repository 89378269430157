.container_formcuentas {
    float: left;
    width: 100%;

    .input_file {
        width: 0.1px;
        height: 0.1px !important;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }

    .label_filec {
        border: 1px solid #2942a9 ;
        background-color: rgba(#2942a9, .5);
        color: #2942a9 ;
        width: 80%;
        max-width: 100%;
        height: 50px;
        font-size: 1.25rem;
        font-weight: 700;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
        display: inline-block;
        overflow: hidden;
        padding: 0;
        border-radius: 8px;
        display: flex;
        justify-content: flex-end;

        span {
            width: 100%;
            color: #fff;
            font-weight: bold;
            font-size: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        strong {
            height: 100%;
            color: #f1e5e6;
            background-color: #2942a9 ;
            display: inline-block;
            padding: 0.625rem 1.25rem;
            display: flex;
            justify-content: center;
            align-items: center;

            p {
                margin: 0;
                margin-left: 5px;
            }
        }
    }
}


/*=============================================
=            Sección de editar data o mostrar data en cuentas por cobrar            =
=============================================*/
.btns_cuentas {
    float: left;
    width: 100%;
    margin-top: 15px;

    .form_btnsc {
        float: left;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .btn_cuenaform {
        float: left;
        width: 150px;
        background: #fff;
        border: 1px solid #eee !important;
        text-transform: uppercase;
        font-weight: bold !important;
        font-size: 15px !important;
        letter-spacing: 0.5px;
        color: #2942A9;
        box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.08);
    }

    .btn_procesarcuentas {
        float: left;
        width: 150px;
        background: #2942A9;
        border: none !important;
        text-transform: uppercase;
        font-weight: bold !important;
        font-size: 15px !important;
        letter-spacing: 0.5px;
        color: #fff;
        box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.08);
        margin-left: 10px;

        &:hover {
            color: #fff;
        }
    }
}

.container_datacuenta {
    float: left;
    width: 100%;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.08);
    border: 1px solid #eee;
    margin-top: 10px;
    padding: 20px;
}

.title_cuentasl {
    border-bottom: none;

    .title_iteml {
        font-weight: bold;
        color: #2942a9;
        text-transform: uppercase;
        font-size: 15px;
    }

    tr {
        border-bottom: none;
        &:hover {
            background: #fff;
        }

        th {
            border-bottom: none !important;
        }
    }
}

.body_cuentast {
    tr {
        border: none !important;

        &:hover {
            background: #fff;
        }

        td {
            border: none !important;
            padding: 0 !important;

            input {
                float: left;
                width: 100%;
                border: 1px solid #eee;
                padding: 5px 10px;
                font-size: 13px;

                &:focus {
                    outline: none;
                    background: #f9f9f9;
                }
            }
        }
    }

    .index_cuenta {
        font-size: 13px;
        font-weight: bold;
        color: #2942a9;
    }
}

.error_valuecuenta {
    float: left;
    width: 100%;
    border: 1px solid rgba(254, 94, 61, 0.8) !important;
    background: rgba(254, 94, 61, 0.3);
    padding: 5px 10px;
    font-size: 13px;
}

.faler {
    float: left;
    width: 100%;
}

.container_buttonsexcel {
    float: left;
    width: 100%;
    padding: 0 10px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p{
        float: left !important;
        width: 100% !important;
        text-align: center !important;
        margin-bottom: 25px !important;
    }
}

.btn_procxc {
    float: left;
    width: 100px;
    background: var(--bg-azul);
    color: var(--blanco);
    font-size: var(--text);
    font-weight: 400;
    margin: 0 5px;
 
       &:active {
          box-shadow: none !important;
       }
 
       &:focus {
          outline: 0 !important
       }
 
       &:hover {
          background: var(--bg-rojo);
          color: var(--blanco);
       }
 }

 .btn_cancelcxc {
    float: left;
    width: 100px;
    background: var(--bg-rojo);
    color: var(--blanco);
    font-size: var(--text);
    font-weight: 400;
    margin: 0 5px;
 
       &:active {
          box-shadow: none !important;
       }
 
       &:focus {
          outline: 0 !important
       }
 
       &:hover {
          background: var(--bg-azul);
          color: var(--blanco);
       }
 }
 
.btn_lislotes {
    @include btnPagalo(15px);
}

.btncuenta_sms, .btncuenta_correo {
   float: left;
   background: #fff;
   border: 1px solid #eee !important;
   font-weight: bold !important;
   font-size: 12px !important;
   letter-spacing: 0.5px;
   color: #2942A9;
   box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.08);
   font-weight: 300 !important;

}

.btncuenta_correo {
    margin-right: 5px;
}

.btn_editarCuentap {
    @include btnPagalo(18px);
    width: 20%;
    margin-top: 15px;

}

.contcuenta_estado{
    span{
        padding: 5px;
        padding-left: 30px;
        font-weight: normal;
        font-size: 10px;
        border-radius: 3px;
        margin:3px 0;
        box-shadow: 0 3px 7px 0 rgba(50, 50, 93, 0.02), 0 3px 7px 0 rgba(0, 0, 0, 0.05);
    }
}

.icocx_creado {
    height: 20px;
    background:#8EB6E8 url('img/icocx-creada.svg') no-repeat 8px center;
    background-size: 13px;
    border:1px solid #006DF0;
}

.icocx_espera {
    height: 20px;
    background:#fdf068  url('img/icocx-pendiente.svg') no-repeat 8px center;
    background-size: 13px;
    border:1px solid #f4e329;
}

.icocx_recha {
    height: 20px;
    background:#fd979e   url('img/icocx-rechazada.svg') no-repeat 8px center;
    background-size: 13px;
    border:1px solid #f97c85;
}

.icocx_aceptado {
    height: 20px;
    background:#afef80    url('img/ico-cx-aceptada.svg') no-repeat 8px center;
    background-size: 13px;
    border:1px solid #91DC5A;
}

.icocx_eliminada {
    height: 20px;
    background:#fd979e   url('img/icocx-eliminada.svg') no-repeat 8px center;
    background-size: 13px;
    border:1px solid #f97c85;
}

span.dnone_phonetd {
    display: none !important;
}

.frm__containr {
    float: left;
    width: 100%;
    height: 19vh;
}

.contianer_pagination {
    float: left;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .pagination > .disabled > span, .pagination > .disabled > span:hover, .pagination > .disabled > span:focus, .pagination > .disabled > a, .pagination > .disabled > a:hover, .pagination > .disabled > a:focus {
        color: #fff;
        background: transparent;
    }

    .pagination {
        border-radius: 30px !important;
        background: linear-gradient(to right, #f26567 0%, #f6a054 100%); 
        padding: 10px 0;
    }

    .pagination > li > a, .pagination > li > span {
        background: transparent;
        color: #fff;
        border: none;
    }

    .pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {
        background: #2942A9 !important;
        border: none !important;
        cursor: default;
        border-radius: 50%;
    }
}

.filtro__colina {
    height: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.ico-excel{
    float: left;
    width: 100%;
    height: 36px;
    padding: 7px 10px 7px 10px;
    border-radius: 5px;
    border: none !important;
    background: #ccc url('img/ico-excel.svg') no-repeat center;
    background-size: 15px;
    color: #fff;
    font-size: 17px;
    font-weight: normal;
}

.container_acciones {
    float: left;

    @include to(m) {
       float: left;
       width: 100%;
       display: flex;
       justify-content: space-around;
       align-items: center;
       margin-top: 10px;
    }

       .btn_opcion {
          @include btnPoductos(14px);
          margin: 0 5px;
       }

 }

 .mtop_formcuenta {
    float: left;
    width: 100%;
    margin-top: 45px;
 }
 
 .monedacxc {
    -webkit-appearance: none;
    height: 50px !important;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #ddd !important;
    background: var(--blanco);
    margin: 0;
    font-weight: bold;
    font-size: var(--text);
 }
 
 .link_plantilla {
    float: left;
    width: 100%;
    text-align: center;
    color: var(--morado);
    font-size: var(--text);
    font-weight: 500;
 
    &:hover {
       color: var(--morado);
    }
 }
 
 .btn_resetc {
    @include btnPoductos(18px);
    width: 150px;
    margin: 0 5px;
 }
 
 .btn_enviarc {
    @include btnPoductos(18px);
    width: 150px;
    background: var(--bg-azul);
    color: var(--blanco);
    margin: 0 5px;
 }
 
 .center_formcxc {
    float: left;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
 }
 
 .ico_plantilla {
    float: left;
    width: 100%;
    height: 60px;
    background: url('img/ico-plantilla.svg') no-repeat center;
    background-size: 28%;
 }
 
 .ico_archivo {
    float: left;
    width: 100%;
    height: 60px;
    background: url('img/ico-datosing.svg') no-repeat center;
    background-size: 25%;
 }
 
 .ico_guardarcxc {
    float: left;
    width: 100%;
    height: 80px;
    background: url('img/ico-guardacxc.svg') no-repeat center;
    background-size: 30%;
 }
 
 .ico_seleccxc {
    float: left;
    width: 100%;
    height: 80px;
    background: url('img/ico-selecxc.svg') no-repeat center;
    background-size: 80%;
 }
 
 .mtoptuto {
    margin-top: 20px;
 }
 
 .ico_nomcxc {
    float: left;
    width: 100%;
    height: 80px;
    background: url('img/ico-nomxcx.svg') no-repeat center;
    background-size: 70%;
 }
 
 .ico_descrcxc {
    float: left;
    width: 100%;
    height: 80px;
    background: url('img/ico-descricxc.svg') no-repeat center;
    background-size: 28%;
 }
 
 .ico_monedacxc {
    float: left;
    width: 100%;
    height: 80px;
    background: url('img/ico-monedacxc.svg') no-repeat center;
    background-size: 40%;
 }
 
 .ico_listocxc {
    float: left;
    width: 100%;
    height: 80px;
    background: url('img/ico-listocxc.svg') no-repeat center;
    background-size: 40%;
 }
 
 .ico_verificacxc {
    float: left;
    width: 100%;
    height: 80px;
    background: url('img/ico_verifica.svg') no-repeat center;
    background-size: 35%;
 }
 
 .ico_validacxc {
    float: left;
    width: 100%;
    height: 80px;
    background: url('img/ico_valida.svg') no-repeat center;
    background-size: 45%;
 }
 
 .ico_editacxc {
    float: left;
    width: 100%;
    height: 80px;
    background: url('img/ico_edita.svg') no-repeat center;
    background-size: 60%;
 }
 
 .max_tutorialcxc {
    max-width: 750px !important;
    width: 750px !important;
 }
 
 .btn_tutorial{
    @include btnPoductos(18px);
    width: 150px;
    background: var(--bg-azul);
    color: var(--blanco);
    padding: 5px 1px 7px 1px;
    margin: 0 2px 4px 7px !important;
 }
 
 .btn_tutorial_footer{
    @include btnPoductos(18px);
    width: 150px;
    color: var(--blanco);
    padding: 5px 1px 7px 1px;
    margin: 7px 2px 4px 7px;
    background: #CCCCCC;
 }
 
 .btn_validar_cc{
    @include btnPoductos(14px);
    background: #7063F1;
    color: #FAF9FE;
    margin: 0 5px;
 }
 
 .btn_cuenta_cc{
    color: var(--morado);
    background: #fff;
    font-size: 17px;
    float: left;
    text-align: left;
    margin-bottom: 8px;
    margin-top: 8px;
    padding: 6px 6px 9px 0px;
    border-radius: 5px;
    box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
    font-weight: 600;
    letter-spacing: 0.01em;
    width: 109px;
    text-align: center;
 }
 
 .btn_lote_cc{
    @include btnPoductos(18px);
    background: var(--bg-azul);
    color: var(--blanco);
    margin: 7px 2px 4px 7px;
    width: 109px;
    padding: 7px 1px 9px 4px;
 }
 
 .table > thead.title_cuentas_cc > tr > th {
    border-bottom: none;
    font-weight: bold;
    color: #707070;
    text-transform: uppercase;
    font-size: var(--text);
    border-bottom: solid 1px;
 }
 
 .nuTable table tbody tr td{
    color: #707070;
    font-size: 15px;
 }
 
 .estadoAceptado_cc{
    background: #82D490;
    text-align: center;
 }
 .estadoRechazado_cc{
    background: #FF7878;
    text-align: center;
 }
 .estadoPendiente_cc{
    background: #F8DB8F;
    text-align: center;
 }
 .estadoCreado_cc{
    background: #788EFF;
    text-align: center;
 }
 .text_estado{
    color: #fff;
 }
 
 .btn-busquedacxc{
    float: left;
    width: 100%;
    height: 36px;
    padding: 7px 10px 7px 10px;
    border-radius:0 5px 5px 0;
    border:none !important;
    background: #f9f9f9 url('img/icobuscar.svg') no-repeat center;
    background-size: 15px;
    color: $principal;
    font-size: 17px;
    font-weight: normal;
 
    &:hover{
        background: #fff url('img/icobuscar.svg') no-repeat center;
        background-size: 15px;
    }
  }
 
  .total_lotes{
    border-bottom: none;
    font-weight: bold;
    color: #707070;
    text-transform: uppercase;
    font-size: var(--text);
  }
 
  .table > thead.tbl_lotes > tr > th{
    font-weight: bold;
    color: #707070;
    text-transform: uppercase;
    font-size: var(--text) !important;
    border-bottom: solid 1px;
  }
 
  .btn_sucess_cc{
    color: #fff;
    background-color: #82D490;
  }
 
  .btn_info_cc{
    color: #fff;
    background-color: #788EFF;
  }
 
  .btn_warning_cc{
    color: #fff;
    background-color: #F8DB8F;
  }
 
 .modal_btnConfirmar {
    position: absolute;
    width: 487px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 250px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0px 100px 0 rgba(0, 0, 0, 0.3);
    z-index: 300;
 }
 
 .contenido_btnConfirmar {
    float: left;
    width: 100%;
    padding: 0 10px;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
 
       p {
          float: left !important;
          width: 100% !important;
          text-align: center !important;
          margin-bottom: 25px !important;
          font-size: var(--text);
          color: var(--negro);
       }
 }
 
 .focus_cc {
    background: var(--bg-rojo) !important;
 
    td {
       color: #fff !important;
    }
 }
 
 .seol_lote {
    background: var(--gris) !important;
 }
 .box_reject{
    height: 140px;
    max-height: 140px;
 }
 
 .scrollboxx{
    overflow-x: hidden !important;
    overflow-y: auto !important;
 
    &::-webkit-scrollbar {
       width: 5px !important;
       background-color: #CCCCCC !important;
    }
 
    &::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
    background: #2942a9 !important;
    }
  }
 
  .btn-resumencxc{
    float: left;
    width: 50%;
    padding: 10px;
    border-radius: 5px;
    border: none !important;
    background: #ff6e64;
    background: linear-gradient(90deg, #ff6e64 0%, #ff9951);
    color: #fff;
    font-size: 17px;
    font-weight: normal;
  }
 
 .btn_vermas{
    width: 40px;
    height: 35px;
    border:1px solid #ddd;
    background: #fff url('img/icover.svg') no-repeat center;
    background-size: 20px;
 }
 
 .container_funcionpos {
    float: left;
    padding: 10px;
    width: 550px;
    max-width: 550px;
 
    h1 {
       float: left;
       text-align: center;
       background: none !important;
       box-shadow: none !important;
       font-size: var(--sub_title);
       font-weight: 500;
       color: var(--negro);
       margin-top: 0;
       margin-bottom: 15px;
    }
 
    p {
       float: left;
       width: 100%;
       text-align: center;
       color: var(--grisos);
       font-size: var(--sub_text);
       line-height: 18px;
    }
 
   //  .ico_tcliente {
   //     float: left;
   //     width: 100%;
   //     height: 80px;
   //     background: url('img/icov/ico-tcliente.svg') no-repeat center;
   //     background-size: 35%;
   //  }
 
   //  .ico_tproducto {
   //     float: left;
   //     width: 100%;
   //     height: 80px;
   //     background: url('img/icov/ico-tproducto.svg') no-repeat center;
   //     background-size: 50%;
   //  }
 
   //  .ico_ttarjeta {
   //     float: left;
   //     width: 100%;
   //     height: 80px;
   //     background: url('img/icov/ico-ttarjeta.svg') no-repeat center;
   //     background-size: 50%;
   //  }
 
   //  .ico_tredes {
   //     float: left;
   //     width: 100%;
   //     height: 80px;
   //     background: url('img/icov/ico-tredes.svg') no-repeat center;
   //     background-size: 50%;
   //  }
 }