$max-grid-columns : 20;
$gap              : $gutter * 2;

@mixin grid-column-width($i) {
  $gaps                 : $i - 1;
  $internal-gap         : ($gap * $gaps) / $i;
  $column-width         : calc((100% / #{$i}) - #{$internal-gap});
  grid-template-columns : repeat($i, $column-width);
}

.ed-grid {
  display         : grid;
  grid-column-gap : $gap;
  max-width       : $max-width;
  margin-left     : 1rem;
  margin-right    : 1rem;

  @include from($max-width) {
    width        : $max-width;
    margin-left  : auto;
    margin-right : auto;
  }

  .ed-grid,
  &.full {
    width        : 100%;
    max-width    : 100%;
    margin-right : 0;
    margin-left  : 0;
  }

  @each $size, $screen-size in $breakpoints {
    // Columnas en el container
    @if $size == s {
      @for $i from 2 through $max-grid-columns {
        &.cols-s-#{$i} {
          //grid-template-columns: repeat($i,1fr);
          @include grid-column-width($i);
        }
      }
    } @else {
      @media all and (min-width: $screen-size){
        @for $i from 2 through $max-grid-columns {
          &.cols-#{$size}-#{$i} {
            //grid-template-columns: repeat($i,1fr);
            @include grid-column-width($i);
          }
        }
      }
    }

    // Columnas en los items
    @if $size == s {
      @for $i from 1 through $max-grid-columns {
        > .span-s-#{$i} {
          grid-column: span $i;
        }
      }
    } @else {
      @media all and (min-width: $screen-size){
        @for $i from 1 through $max-grid-columns {
          > .span-#{$size}-#{$i} {
            grid-column: span $i;
          }
        }
      }
    }
  }
}
