.container_pasolinea {
    margin-bottom: 25px;
}

.title_pagolinea {
    margin-top: 55px;

    h3 {
        float: left;
        width: 100%;
        text-align: center;
    }
}

.bottom_table {
    margin-bottom: 10px !important;
}

.container_buttonptodo {
    float: left;
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    .btn_ptodo {
        float: left;
        width: 25%;
        padding: 8px;
        border-radius: 5px;
        text-decoration: none;
        background: #fff;
        border: 1px solid #2942a9;
        color: #2942a9;
        font-size: 15px;
        font-weight: normal;
        text-align: center;
        outline: none !important;
    }
}

.infoEmpresa{
    float: left;
    width: 100%;
    margin: 0;
    padding: 10px 20px;
    border-top: 1px dashed #333;

    @include media(xs) {
        margin-top: 15px;
    }
}

.tel_pago {
    float: left;
    width: 100%;
    color: #000 !important;
}

.total_ptlinea {
    float: left;
    width: 100%;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 0;
}

p.totalpago_linea {
    float: left;
    width: 100%;
    margin: 0 !important;
    text-align: center !important;
    font-size: 20px !important;
    font-weight: bold !important;
    text-transform: uppercase !important;
    color: #2942a9;
}

.ico_correop {
    float: left;
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    padding-left: 40px;
    background: url('img/ico-correop.svg') no-repeat 7px center;
    background-size: 27px;
}

.ico_telefonop {
    float: left;
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    padding-left: 40px;
    background: url('img/ico-telefonop.svg') no-repeat 7px center;
    background-size: 27px;
}

.ico_direccionp {
    float: left;
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    padding-left: 40px;
    background: url('img/ico-direccionp.svg') no-repeat 7px center;
    background-size: 27px;
}

.dflex_start  {
    display: flex;
    justify-content: flex-start;
}

.logos_pagolinea {
    float: left;
    width: 100%;
    padding-left: 15px;

    @include media(xs) {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
}

.mbottom {
    margin-bottom: 20px;
}

.btn_cancelarlinea {
    float: left;
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    text-decoration: none;
    background: #fff;
    border: 1px solid #2942a9;
    color: #2942a9;
    font-size: 15px;
    font-weight: normal;
    text-align: center;
    outline: none !important;
}

$color1: #f4f4f4;
$color2: #2942A9;

.container_pagoLinea {
    float: left;
    width: 100%;
    height: 100%;

    @include media(xs) {
        background-size: 300%;
        background-position: 100%;
    }
}

.mb_footerpago {
    @include media(xs) {
        margin-bottom: 30%;
    }
}

.max_widthForm {
    max-width: 480px;
    margin-bottom: 15px;

    @include media(xs) {
        max-width: 100%;
        margin: 0;
        padding: 0;
    }
}

.header_pagoLinea {
    float: left;
    position: relative;
    width: 100%;
    height: 200px;
    background: var(--portada) no-repeat center;
    background-size: cover;
    // background-size: 100% auto;
    border-radius: 0 0 50px 50px;

    @include media(xs) {
        padding: 0 10px;
        height: 180px;
    }

        .avatar {
            margin-left: 10px;
            margin-top: 10px;
            height: 100px;
            width: 100px;
            border-radius: 5px;
            max-width: 100%;
            border: 4px solid #fff;

            @include media(xs) {
                margin: 0;
                margin-top: 15px;
                height: auto;
            }
        }

        .contianer_descriptionheadt {
            float: left;

            .username {
                font-size: 25px;
                color: #fff;
                font-weight: bold;
            }

            .telefono_frm {
                font-size: 18px;
                color: #fff;
                font-weight: normal;
            }
        }
}

.container__cardfrm {
    float: left;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -35px;

    .pago_container {
        float: left;
        width: 90%;
        background: #fff;
        border-radius: 15px;
        box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.08);
        padding: 10px;
    }
}

.form_cuentas {
    float: left;
    width: 100%;

    h1 {
        font-size: 18px;
        margin-top: 5px;
        margin-bottom: 10px;
        font-weight: normal;
    }
}

.mtop_btn {
    margin-top: 15px;
}

.btn_pagocuenta {
    background: linear-gradient(90deg,#ff6e64 0%,#ff9951);
    color: #fff;
    font-size: 15px;
    font-weight: normal;
    margin-top: 15px;    
    width: 60%;

    &:hover {
        color: #fff;
    }
}

.montoCuenta {
    font-size: 25px;
    font-weight: bold;
    color: #000;
}

.montoCuentaAbono {
   font-size: 19px;
   color: #232121;
   line-height: 20px;
}

.btn_abonopcuenta {
    background: linear-gradient(90deg,#5477f0 0%,#6c64ee);
    color: #fff;
    font-size: 15px;
    font-weight: normal;
    margin-top: 15px;    
    width: 60%;

    &:hover {
        color: #fff;
    }
}

.header_detalle {
    --radius: 280px;
    
    float: left;
    position: relative;
    width: 100%;
    height: 200px;
    background: radial-gradient(circle var(--radius) at 10% 5%, #ff6567 70%, #fff 50%);
    padding-top: 15px;

    @include media(xs) {
        padding: 0 10px;
        height: 180px;
    }

        .avatar {
            margin-left: 10px;
            margin-top: 0;
            height: 9.23em;
            width: auto;
            border-radius: 18em;
            max-width: 100%;

            @include media(xs) {
                margin: 0;
                margin-top: 15px;
                height: 100px;
            }
        }

        .contianer_descriptionheadt {
            float: left;

            .telefono_frm {
                font-size: 18px;
                color: #000;
                font-weight: normal;
            }
        }
}

.container_itemlist {
    float: left;
    width: 100%;
    background: #fff;
    border-radius: 0 0 10px 10px;
    box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.08);
    padding: 10px;
}

.list_container {
    float: left;
    width: 100%;
    max-height: 350px;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 2px;
        background-color: #ccc;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #2942a9;
      background: linear-gradient(to right, #2942a9 0%, #002883 100%);
    }

    .container_cliente {
        float: left;
        width: 100%;
        
        p {
            font-size: 18px;
            font-weight: 500;
            color: #000;
        }
    }

    .card_transaccion {
        float: left;
        width: 100%;
        background: #fff;
        border-radius: 10px;
        border: 1px solid #eee;
        box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.08);
        padding: 10px !important;
        margin-bottom: 15px;
        overflow: hidden;

        .fecha_container {
            float: left;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;

            p {
                float: left;
                width: 100%;
                font-size: 12px;
                font-weight: 500;
                color: #000;
                margin: 0;
                margin-bottom: 5px;

                @include media(xs) {
                    font-size: 12px;
                }
            }

            p.color_fecha {
                color: #999;
            }
        }

        .monto_tran {
            p {
                font-size: 18px;
                font-weight: bold;
                color: #000;
                margin: 0;

                @include media(xs) {
                    font-size: 16px;
                }
            }
        }

        .border_ico {
            border-left: 1px solid #e8e8e8;
        }

        .ico_detalle {
            float: left;
            width: 100%;
            height: 50px;
            background: url('img/ico-mas.svg') no-repeat center;
            background-size: 50%;
            transition: all .2s ease;
            cursor: pointer;
        }

        .detalle_container {
            .title_detalle {
                margin: 0;
            }

            .line_detalle {
                float: left;
                width: 100%;
                height: 1px;
                background: #000;
                margin-bottom: 5px;
            }

            .top_productos {
                margin-top: 10px;
            }
        }
    }

    .chk_cuentas {
        float: left;
        width: 100%;
        
        input[type="checkbox"] {
            position: absolute;
            opacity: 0;

            +.chk-label {
                &:before {
                    content: '';
                    background: $color1;
                    border-radius: 100%;
                    border: 1px solid darken($color1, 25%);
                    display: inline-block;
                    width: 1.4em;
                    height: 1.4em;
                    position: relative;
                    top: -0.2em;
                    margin-right: 1em; 
                    vertical-align: top;
                    cursor: pointer;
                    text-align: center;
                    transition: all 250ms ease;
                }
            }

            &:checked {
                + .chk-label {
                    &:before {
                        background-color: $color2;
                        box-shadow: inset 0 0 0 4px $color1;
                    }
                }
            }
            
            &:focus {
                + .chk-label {
                    &:before {
                        outline: none;
                        border-color: $color2;
                    }
                }
            }

            &:disabled {
                + .chk-label {
                    &:before {
                        box-shadow: inset 0 0 0 4px $color1;
                        border-color: darken($color1, 25%);
                        background: darken($color1, 25%);
                    }
                }
            }

            + .chk-label {
                &:empty {
                    &:before {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

.h_card {
    height: 100px;
    transition: height .2s ease;
}

.h_contcard {
    height: 100px;
}

.h_cardDetalle {
    height: 185px;
    transition: height .2s ease;
}

.h_cardDetallecuota {
    height: 270px;
    transition: height .2s ease;
}

.botom_detaller {
    margin-bottom: 5px;
}

.ico_opendetalle {
    transition: all .2s ease;
    transform: rotate(180deg);
}

.border_tranaccept {
    border-left: 8px solid #66cd00 !important;
}

.buttonsc_container {
    float: left;
    width: 100%;
    margin-top: 25px;    
}

.cuentasflex_center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ico_cerrarabono {
    float: left;
    width: 50px;
    height: 50px;
    background: url('img/ico-elitem.svg') no-repeat center;
    background-size: 50%;
    cursor: pointer;
}

.container_frmtarjeta {
    float: left;
    width: 100%;

    .container_tarjeta {
        float: left;
        width: 100%;
        background: #fff;
        border-radius: 0 0 10px 10px;
        box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.08);
        padding: 10px;

        .totalpagoCuetna {
            float: left;
            width: 100%;
            font-size: 18px;
            font-weight: 500;
            color: #000;
            margin-bottom: 5px;
            text-align: center;
        }

        .totalmontoCuenta {
            font-size: 25px;
            font-weight: bold;
            color: #000;
            margin-top: 0;
        }

        .formpago_tarc {
            label {
                color: #454545 !important;
                font-weight: 400 !important;
                font-size: 15px !important;
            }

            input.text_card {
                background: #fff !important;
                border-radius: 8px !important;
                border: 1px solid #454545 !important;
                color: #454545 !important;
                margin-bottom: 10px !important;
            }
        }
    }
}

.container_shared {
    float: left;
    width: 90%;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.08);
    padding: 10px;
    margin-top: 0;

    a {
      color: #6c64ee;
    }
}

.flex_centeritem {
    display: flex;
    justify-content: center;
    align-items: center;
}

.container_qr {
    float: left;
    position: relative;
    width: 100%;
    // height: 250px;
    background: linear-gradient(90deg,#ff6e64 0%,#ff9951);
    border-radius: 0 0 10px 10px;
    padding-top: 20px;

    .ico_chektran {
        float: left;
        width: 100%;
        height: 50px;
        background: url('img/icov/ico-info.svg') no-repeat center;
        background-size: 50px;
    }

    .description_pagoqr {
        float: left;
        width: 100%;
        margin-top: 10px;

            p {
                float: left;
                width: 100%;
                text-align: center;
                margin-bottom: 5px;
                font-size: 15px;
                font-weight: normal;
                color: #fff;
            }
            
            h1 {
                float: left;
                width: 100%;
                text-align: center;
                margin-top: 0;
                margin-bottom: 5px;
                font-size: 20px;
                font-weight: bold;
                color: #fff;
            }
    }

    .container_boxqr {
        float: left;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 5%;

        .boxqr_item {
            float: left;
            width: 90%;
            background: #fff;
            border-radius: 15px;
            box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.08);
            padding: 10px;
        }

        .container_icophone {
            float: left;
            width: 100%;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;

            .ico_phoneqr {
                float: left;
                width: 50px;
                height: 50px;
                background: url('img/icov/ico-telefono.svg') no-repeat center;
                background-size: 25px;
            }
        }

        .info_pagoqr {
            float: left;
            width: 100%;

                 p {
                    float: left;
                    width: 100%;
                    text-align: left;
                    margin-bottom: 5px;
                    font-weight: 500;
                    font-size: 18px;
                    color: #000;
                    line-height: 20px;

                    @include media(xs) {
                        font-size: 15px;
                        line-height: 15px;
                    }
                }
        }

        .contaienr_imgqr {
            float: left;
            width: 100%;
            height: 150px;
            margin-top: 20px;
            margin-bottom: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .contaner_btndescarga {
            float: left;
            width: 100%;
            margin-top: 20px;
            display: flex;
            justify-content: center;
            align-items: center;

            .btn_descargaqr {
                color: #fff;
                font-weight: normal;
                font-size: 18px;
                background: url('img/icov/ico-descarga.svg') no-repeat left, linear-gradient(90deg,#5477f0 0%,#6c64ee);
                background-size: 20px;
                padding-left: 30px;

                &:hover {
                    color: #fff;
                }
            }
        }
    }
}

.cont_btnwhats {
    float: left;
    width: 100%;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    .btn_sharedwhats {
        color: #fff;
        font-weight: normal;
        font-size: 18px;
        background: url('img/icov/ico-whats.svg') no-repeat left;
        background-size: cover;
        width: 160px;
        height: 45px;

        &:hover {
            color: #fff;
        }
    }
}

.wbtncard {
    @include media(xs) {
        width: 80%;
    }
}

.btn_atrascp {
    float: left;
    background: #fff;
    border: 1px solid #ddd;
    color: #000;
    font-size: 15px;
    font-weight: normal;
    margin-top: 15px;
    width: 80%;
}

.top_footerline {
    margin-top: 25%;
}

.h_autolines {
    height: auto !important;
}

.logo_pci{
   float: left;
   width: 120px;
   height: 70px;
   background: url('img/logopci.png') no-repeat center;
   background-size: 100%;
}

.logo_cybs{
   float: left;
   width: 120px;
   height: 70px;
   background: url('img/logocybersource.png') no-repeat center;
   background-size: 100%;
}

.logo_visa{
   float: left;
   width: 120px;
   height: 70px;
   background: url('img/logovisa.png') no-repeat center;
   background-size: 100%;
}

.logo_master{
   float: left;
   width: 120px;
   height: 70px;
   background: url('img/logomaster.png') no-repeat center;
   background-size: 100%;
}

.espac{
   margin:5px 20px;
}

.foo_regis{
   padding-top: 5px !important;
   float: left;

   p {
      margin:15px 0 !important;
      color: #999 !important;
   }
}

.footerbg_gris {
    background: #F4F7FD !important;
}

.btn_tutorial {
   border-radius: 50px;
   background: linear-gradient(90deg,#ff6e64 0%,#ff9951);
   color: #fff;
   font-size: 15px;
   font-weight: normal;
   margin-top: 10px;

   &:hover {
      color: #fff;
      background: linear-gradient(90deg,#5477f0 0%,#6c64ee);
   }
}

.w_btnabonou {
   width: 60%;
}

.tuto_frame {
   iframe {
      float: left;
      width: 100%;
      height: 500px;

      @include to(m) {
         height: 350px;
      }
   }
}

.btn__cvvavon {
  float: left;
  width: 25px;
  height: 25px;
  position: relative;
  top: -2px;
  bottom: 0;
  left: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  background: url('img/icov/ico-duda.svg') no-repeat center;
  background-size: 13px;

    @include media(xs) {
      top: -3px;
    }
}

.monto_container {
    float: left;
    width: 100%;
}

// Hack button
.widh_fullbtn {
    width: 100% !important;
}


.color_red{
    color: rgba(202, 23, 23, 0.938) !important;
}