.mx-soli {
    width: 600px;
    max-width: 600px;

    @include media(xs) {
        width: 100%;
    }
}

.aremobi{

    @include media(xs) {
        padding: 0 !important;
    }
}


.backsoli {
    float: left;
    width: 100%;
    margin-top: 15px;
    background: #fff;
    box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.08);
    border: 1px solid #ddd;
    padding-bottom: 15px;
    border-radius: 5px;

     @include media(xs) {
       margin-top: 0 !important;
         border-radius: 0;
           border: none;
            border-bottom: 1px solid #ddd;
    }
}

.backsolicitud {
    float: left;
    width: 100%;
    margin-top: 10%;
    background: #fff;
    box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.08);
    border: 1px solid #ddd;
    padding-bottom: 15px;
    border-radius: 5px;

     @include media(xs) {
       margin-top: 0 !important;
         border-radius: 0;
           border: none;
            border-bottom: 1px solid #ddd;
    }
}

.titleEmpresa{
    padding-top:65px;
    text-align: center;
    padding-bottom: 20px;
}


.banner_soli {
    float: left;
    width: 100%;
    padding: 0;

        .soli_item {
            margin-top: 10px;
            float: left;
            width: 100%;
            height: 200px;
            background: #f9f9f9;
            border: 1px solid #ddd;
            box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.12);
            display: flex;
            justify-content: center;
            align-items: center;

             @include media(xs) {
                        margin-top: 0px;
                        height: 180px;
                    }

                .banner_logo {
                    float: left;
                    width: 130px;
                    height: 130px;
                    background: #f9f9f9;
                    box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.12);
                    border: 10px solid #fff;
                    position: absolute;
                    bottom: -60px;

                    @include media(xs) {
                        top: 110px;
                        bottom: 0;
                    }
                }

                p {
                    float: left;
                    width: 100px;
                    font-size: 15px;
                    font-weight: bold;
                    text-align: center;
                    line-height: 18px;
                    position: absolute;
                    bottom: -120px;
                }
        }
}

.body_soli_monto {
    width: 100%;
    margin-top: 275px;
    padding : 0 20 0 0;

        @include media(xs) {
             margin-top: 250px;
        }

    .monto_container {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;

            h3 {
                float: left;
                width: 100%;
                margin-top: 0;
                font-size: 20px;
                font-weight: bold;
                text-align: center;
            }

             h1 {
                 float: left;
                width: 100%;
                margin-top: 0;
                font-size: 15px;
                font-weight: bold;
                text-align: center;
            }


            p {
                float: left;
                width: 80%;
                margin-left: 10%;
                margin-right: 10%;
                margin-top: 0;
                font-size: 25px;
                font-weight: bold;
                text-align: center;
                margin-bottom: 10px;
                background: #f9f9f9;
                border-radius: 5px;


                span {
                    font-size: 15px;
                }
            }
    }

    
}

.body_soli_monto_linea {
    width: 100%;
    margin-top: 150px;
    padding : 0 20 0 0;

        @include media(xs) {
             margin-top: 50px;
        }

    .monto_container {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;

            h3 {
                float: left;
                width: 100%;
                margin-top: 0;
                font-size: 20px;
                font-weight: bold;
                text-align: center;
            }

             h1 {
                 float: left;
                width: 100%;
                margin-top: 0;
                font-size: 15px;
                font-weight: bold;
                text-align: center;
            }


            p {
                float: left;
                width: 80%;
                margin-left: 10%;
                margin-right: 10%;
                margin-top: 0;
                font-size: 25px;
                font-weight: bold;
                text-align: center;
                margin-bottom: 10px;
                background: #f9f9f9;
                border-radius: 5px;


                span {
                    font-size: 15px;
                }
            }
    }

    
}

.btn_center{
  float: left;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 5px;

  a{
    color: #333;
    background: #fff;
    font-size: 13px;
    float: left;
    width: 150px;
    text-align: center;
    margin-left:5px;
    padding: 12px 15px;
    border-radius: 5px;
    /* box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07); */
    font-weight: bold;
    letter-spacing: 0.01em;
    cursor: pointer;
    border: 1px solid #ddd;

    &:hover{
      transition: all 0.5s ease;
      text-decoration: none;
      background: #2942a9;
      background: linear-gradient(to right, #2942a9 0%, #002883 100%);
      color: #fff;
      border:1px solid #2942a9;
      box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
    }


  }

}
.body_soli{
    width: 100%;    

    .monto_container {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;

            h3 {
                float: left;
                width: 100%;
                text-align: center;
                font-size: 15px;
                font-weight: normal;
                margin-bottom: 0;
            }

            p {
                float: left;
                width: 100%;
                margin-top: 0;
                font-size: 25px;
                font-weight: bold;
                text-align: center;

                span {
                    font-size: 15px;
                }
            }
    }

    .descripcion_soli {
        float: left;
        width: 100%;
        margin-top: 15px;

            h3 {
                float: left;
                text-align: left;
                margin: 0;
                font-size: 18px;
                font-weight: normal;
                margin-right: 10px;
            }

            p {
                float: left;
                font-size: 15px;
                font-weight: normal;
                text-align: justify;
                margin-left: 5px;
            }
    }
}

.w_btn {
    width: 65% !important;
}

.conbody {
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.mauto_footer {
    margin-top: auto !important;
}

@include media(xs) {
    .fleft_soli {
        float: left;
        width: 100%;
    }
}

.body_card {
    float: left;
    width: 100%;
    margin-top: 25px;
    padding: 0 15px;
}

#area_tarjeta {
    width: 100%;
    position: relative;
    animation: .3s craparecer;
    overflow-y:auto;
}

@keyframes crocultar {
  from {
    right: 0;
  }
  to {
    right: -30%;
  }
}

@keyframes craparecer {
  from {
    right: -345px;
  }
  to {
    right: 0;
  }
}

#area_tarjeta.ng-leave {
  animation: .3s crocultar;
}

.text_card {
    letter-spacing: 3px;
    padding: 5px; 
    text-align: center;

    @include media(xs) {
        letter-spacing: 2px;
        font-size: 13px;
    }
}

.arealogotar{
       width: 200px;
    margin: 10px auto;

    p{
        font-size: 14px;
        float: left;
        margin-top: 7px;
        font-weight: 600;
        margin-right: 5px;
    }
}

.log_visa{
    float: left;
    width: 50px;
    height: 37px;
    margin-right: 10px;
    background: url('img/log_visa.svg') no-repeat center;
    background-size: 100%;
}

.log_master{
    float: left;
    width: 50px;
    height: 37px;
    margin-right: 10px;
    background: url('img/log_master.svg') no-repeat center;
    background-size: 100%;
}

.log_lets{
    float: left;
    width: 100px;
    height: 50px;
    margin-right: 10px;
    background: url('img/lets.png') no-repeat center;
    background-size: auto 100%;
}

.log_cybs{
    float: left;
    width: 140px;
    height: 50px;
    background: url('img/cybs.png') no-repeat center;
    background-size: auto 90%;
}

.icoju{
   background-position: center !important;
       background-size: auto 170% !important;
}

.input_mrbd{
    border: solid 1px #FF6314 !important;

}

.ico_busposlup {
    background: #fff url('img/icov/ico-buscarposv2.svg') no-repeat 15px center !important;
    background-size: 20px !important;
 }
 
.container_flexcenter {
    float: left;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    
}

h1.bus_ctitle {
    float: left;
    width: 100%;
    text-align: center !important;
    background: none !important;
    box-shadow: none !important;
    font-size: var(--text);
    font-weight: 400;
    color: #999 !important;
 }

.boxcontainer_form {
    float: left;
    padding: 10px;
    width: 700px;
    max-width: 700px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.08);
    border: 1px solid #eee;
    display: flex;
    justify-content: center;
    flex-direction: column;
    // align-items: center;/

    .titulo_pos {
        float: left;
        width: 100%;
        font-size: 14px;
        color: #000;
    }
}

.form_posc {
    float: left;
    width: 100%;
}

.no_bottompos {
    margin-bottom: 0 !important;
}

.input_boxpos_virtual {

    input {
       background: #fff;
       color: #333;
       //box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.03) !important;
       font-size: 15px;
       border-radius: 8px;
       font-weight: 500;
       padding: 7px 5px;
       letter-spacing: 0.03em;
       border: 1px solid #ddd !important;
 
       &:focus {
          background: #fff;
       }
    }
 
    input.no_border {
       border: none !important;
    }
 
    input.no_shadou {
       box-shadow: none !important;
    }
 
    textarea {
       background: #fff;
       color: #2942a9;
       box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.08) !important;
       font-size: 16px;
       border-radius: 10px;
       font-weight: 500;
       padding: 5px;
       letter-spacing: 0.03em;
       border: 1px solid #ddd !important;
 
       &:focus {
          background: #fff;
       }
    }
}

.input_pagorecu {

    input {
       background: #fff;
       color: var(--negro);
       box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.03) !important;
       font-size: var(--text);
       border-radius: 8px;
       font-weight: 500;
       padding: 10px;
       letter-spacing: 0.03em;
       border: 1px solid var(--negro) !important;
 
          &:focus {
             background: #fff;
          }
    }
 }

.input_pagorecuselect {
    float: left;
    width: 100%;
    border: 1px solid var(--negro) !important;
    background: #fff;
    color: var(--negro);
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    font-size: var(--text);
    font-weight: 500;
    padding: 10px;
    -webkit-appearance: none;
 
       &:focus {
          outline: none !important;
       }
 }

 .btn_solirecu {
    float: left;
    width: auto;
    background: var(--bg-rojo);
    color: var(--blanco);
    font-size: var(--sub_text);
    font-weight: 400;
    margin: 0 5px;
 
       &:active {
          box-shadow: none !important;
       }
 
       &:focus {
          outline: 0 !important
       }
 
       &:hover {
          background: var(--bg-azul);
          color: var(--blanco);
       }
 }

.list_clientesnpos {
    float: left;
    width: 100%;
    margin-bottom: 20px;
    border-top: 1px dashed var(--grisos);
    max-height: 225px;
    overflow-y: auto;
 
    &::-webkit-scrollbar {
    width: 2px;
    background-color: #ccc;
    }
 
    &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: var(--bg-azul);
    }
 
       .border_item {
       padding: 10px 0;
       border-bottom: 1px dashed var(--grisos);
       }
 
       .btn_scliente {
          float: left;
          width: 100%;
          background: var(--bg-azul);
          color: #fff;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
 
          &:hover {
             background: var(--bg-rojo);
          }
       }
 
       p {
          font-size: 14px;
          color: #000;
          font-weight: 400;
          margin-bottom: 0;
       }
 }

.list_productosnpos {
float: left;
width: 100%;
margin-bottom: 20px;
max-height: 225px;
overflow-y: auto;

&::-webkit-scrollbar {
width: 2px;
background-color: #ccc;
}

&::-webkit-scrollbar-thumb {
border-radius: 10px;
background: var(--bg-azul);
}

    .border_producto {
        padding: 10px 0;
        border-bottom: 1px dashed var(--grisos);
    }

    .ico_deletep {
        float: left;
        width: 100%;
        height: 25px;
        border-radius: 5px;
        border: 1px solid #eee;
        box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.08);
        background: #fff url('img/ico-eliminarc.svg') no-repeat center;
        background-size: 50%;
        cursor: pointer;
    }

    .ico_masp {
        float: left;
        width: 50%;
        height: 25px;
        border-radius: 5px;
        border: 1px solid #eee;
        box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.08);
        background: #E5E5E5 url('../img/icov/ico-masntienda.svg') no-repeat center;
        background-size: 45%;
        cursor: pointer;
        transform: rotate(180deg);
    }

    .ico_menosp {
        float: left;
        width: 50%;
        height: 25px;
        border-radius: 5px;
        border: 1px solid #eee;
        box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.08);
        background: #E5E5E5 url('../img/icov/ico-menosntienda.svg') no-repeat center;
        background-size: 45%;
        cursor: pointer;
    }
}

.user-profile {
    float: left;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
 
    @include media(xs) {
       margin: 0;
       width: 100%;
       height: 100%;
       justify-content: flex-start;
       // background: url('img/icov/bg-solicitud.svg') repeat center;
       // background-size: cover;
    }
 
    .bandheader {
       float: left;
       width: 500px;
       height: 200px;
       background: linear-gradient(90deg,#ff6e64 0%,#ff9951);
       border-radius: 0 0 50px 50px;
 
       @include media(xs) {
          width: 100%;
          position: absolute;
       }
 
          .avatar {
             margin-left: 1.3em;
             margin-top: 1.3em;
             height: 9.23em;
             width: 9.23em;
             border-radius: 18em;
             max-width: 100%;
 
             @include media(xs) {
                margin: 0;
                margin-top: 15px;
                margin-left: 10px;
                height: auto;
             }
          }
 
          .logo_plfrmmont {
             float: left;
             width: 200px;
             height: 50px;
             background: url('img/icov/logo-pagalo.svg') no-repeat center;
             background-size: 75%;
 
             @include media(xs) {
                background-size: 100%;
             }
          }
 
          .contianer_descriptionheadt {
             float: left;
 
                .username {
                   font-size: 23px;
                   color: #fff;
                   font-weight: bold;
                }
 
                .telefono_frm {
                   font-size: 18px;
                   color: #fff;
                   font-weight: normal;
                }
 
                .container_puntuacion {
                   float: left;
                   width: 100%;
                   height: 25px;
                   display: flex;
                   justify-content: flex-start;
                   align-items: center;
 
                      p {
                         float: left;
                         margin: 0;
                         font-size: var(--sub_text);
                         color: var(--blanco);
                         font-size: $text_ligth;
                      }
 
                      .ico_estrella {
                         float: left;
                         width: 50px;
                         height: 100%;
                         background: url('../img/icov/ico-estrella.svg') no-repeat 5px center;
                         background-size: 40%;
                      }
                }
          }
    }
 
    .container__cardfrm {
       width: 450px;
       height: auto;
       position: relative;
       top: -30px;
       background: #fff;
       border-radius: 15px;
       box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.08);
 
       @include media(xs) {
          width: 90%;
          top: 125px;
       }
    }
 
    .container__cardfrm_pagoenlinea {
        width: 450px;
        height: auto;
        position: relative;
        top: -30px;
        background: #fff;        
        border-radius: 15px;
        box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.08);
        padding: 8px 10px;
  
        @include media(xs) {
           width: 90%;
           top: 125px;
        }
     }

    .titulo_sf {
       font-size: 30px;
       font-weight: bold;
       color: rgb(104, 104, 104);
       margin-bottom: 0;
 
       @include media(xs) {
          font-size: 20px;
       }
    }
 
    .input_boxsf input {
       background: #fff;
       color: #333;
       box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.03) !important;
       font-size: 18px;
       border-radius: 8px;
       font-weight: 500;
       padding: 7px 5px;
       letter-spacing: 0.03em;
       border: 1px solid #ddd !important;
    }
 
    .input__frmsolicitud {
       float: left;
       width: 100%;
       border: 1px solid #aaa;
       border-radius: 5px;
       outline: none;
       padding: 10px 0 10px 35px !important;
       transition: all 0.5s ease;
       margin-top: -9px;
    }
 }

.totalsoli_card {
    float: left;
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    color: #454545;
    margin-top: 10px;
    margin-bottom: 0;
}

.totalsoli_monto {
    float: left;
    width: 100%;
    text-align: center;
    font-size: 30px;
    font-weight: 300;
    color: #454545;
    margin-top: 5px;
    margin-bottom: 0;
}

.bg_descliente {
    float: left;
    width: 100%;
    border-radius: 10px;
    background: #F4F7FD;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 0 5px;
 
       h2 {
          float: left;
          width: 100%;
          text-align: center;
          font-size: 20px;
          font-weight: 400;
          color: #454545;
          margin-top: 10px;
          margin-bottom: 0;
       }
 
       p {
          float: left;
          width: 100%;
          text-align: center;
          font-size: 15px;
          font-weight: 400;
          color: #454545;
          margin-bottom: 5px;
       }
 
       p.decri_soli {
          text-align: left;
       }
 
       .container_productos {
          float: left;
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
 
          p {
             background: #d4dff775;
             float: left;
             width: auto;
             margin: 4px;
             padding: 5px;
             border-radius: 5px;
             color: #000;
             font-weight: 300;
             font-size: 15px;
          }
       }
 }

 .cotainerfrm_card {
    @include cardPago();
 }

 .text_acceptcard {
    float: left;
    width: 100%;
    font-size: 18px;
    text-align: center;
    margin-bottom: 4px;
 }

 .icosoli_cardsaccept {
    width: 250px;
    height: 60px;
    background: url('img/icov/logos_visamaster.svg') no-repeat center;
    background-size: 50%;
 }

 .mbottom_cardpago {
    margin-bottom: 10px;
 }

 .bg_infopcuo {
    float: left;
    width: 100%;
    border-radius: 10px;
    background: #afef80;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 10px;
 
    h1 {
       float: left;
       width: 100%;
       margin-bottom: 10px;
       margin-top: 5px;
       font-weight: bold;
       font-size: 15px;
       text-align: center;
       color: #000;
    }
 
    ol {
       float: left;
       width: 100%;
       font-size: 13px;
       font-weight: 400;
       color: #000;
       text-align: justify;
    }
 }

 .footext_pagalo {
    float: left;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
 
    p {
       text-align: center;
       color: #999 !important;
       cursor: pointer;
       margin: 0;
 
       &:hover {
          text-decoration: underline;
       }
    }
 }

 .scroll__message {
    float: left;
    width: 100%;
    max-height: 310px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 15px 0;

    &::-webkit-scrollbar {
        width: 2px;
        background-color: #ccc;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #2942a9;
      background: linear-gradient(to right, #2942a9 0%, #002883 100%);
    }
}

/* Iconos nueva solicitud */

.btn_sharedsolipago {
    float: left;
    width: 150px;
    background: #fff;
    border: 1px solid #eee !important;
    font-weight: 400 !important;
    font-size: 17px !important;
    letter-spacing: 0.01em;
    color: #000;
    box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.08);
    padding: 10px 15px;
    border-radius: 5px;
    margin: 5px;
 
    &:active {
       box-shadow: none !important;
       outline: none !important;
    }
 
    &:focus {
       color: var(--negro) !important;
       outline: none !important;
    }
}
 
.ico_soliwhatsn {
    background: var(--blanco) url('img/icov/ico-soliwhatsn.svg') no-repeat 8px center !important;
    background-size: 20px !important;

    @include media(xs) {
        width: 100%;
        background: var(--blanco) url('img/icov/ico-soliwhatsn.svg') no-repeat 75px center !important;
        background-size: 20px !important;
    }

    &:hover {
        background: var(--morado) url('img/icov/ico-soliwhatsb.svg') no-repeat 8px center !important;
        background-size: 20px !important;
        color: #fff !important;
    }
}

.ico_solicorreo {
    background: var(--blanco) url('img/icov/ico-solicorreon.svg') no-repeat 20px center !important;
    background-size: 20px !important;

    @include media(xs) {
        width: 100%;
        background: var(--blanco) url('img/icov/ico-solicorreon.svg') no-repeat 75px center !important;
        background-size: 20px !important;
    }

    &:hover {
        background: var(--morado) url('img/icov/ico-solicorreob.svg') no-repeat 20px center !important;
        background-size: 20px !important;
        color: #fff !important;
    }
}
 
.ico_solilink {
    background: var(--blanco) url('img/icov/ico-solilinkn.svg') no-repeat 15px center !important;
    background-size: 20px !important;

    @include media(xs) {
        width: 100%;
        background: var(--blanco) url('img/icov/ico-solilinkn.svg') no-repeat 75px center !important;
        background-size: 20px !important;
    }

    &:hover {
        background: var(--morado) url('img/icov/ico-solilinkb.svg') no-repeat 15px center !important;
        background-size: 20px !important;
        color: #fff !important;
    }
}

.ico_card {
    background: var(--blanco) url('img/icov/credit-card-regular.svg') no-repeat 15px center !important;
    background-size: 20px !important;

    @include media(xs) {
        width: 100%;
        background: var(--blanco) url('img/icov/credit-card-regular.svg') no-repeat 75px center !important;
        background-size: 20px !important;
    }

    &:hover {
        background: var(--morado) url('img/icov/credit-card-regular.svg') no-repeat 15px center !important;
        background-size: 20px !important;
        color: #fff !important;
    }
}
 
.btn_widthshared {
width: 170px !important;
}
 
.btn_secundaryblue {
float: left;
width: 100px;
background: var(--bg-azul);
color: #fff !important;
font-size: var(--sub_text);
font-weight: 400;
margin: 0 5px;

    &:active {
        box-shadow: none !important;
    }

    &:focus {
        outline: 0 !important
    }
}

.dnone__card {
    display: none !important;
}

.tipe_cardintnpos {
    position: relative;
    float: left;
    width: 100%;
 
    &::before {
       content: "";
       float: right;
       width: 50px;
       height: 32px;
       background: #2942a9 var(--image);
       background-repeat: no-repeat;
       background-position: center center;
       background-size: 40px;
       border-radius: 0 5px 5px 0;
       position: absolute;
       right: 0;
       top: 12.5%;
       z-index: 10;
 
       @include media(xs) {
          height: 30px;
          top: 13.1%;
       }
   }
}

.total__{
   font-size: 20px;
    text-align: center;
    padding-bottom: 21px;
}

.total__cuota{
   font-size: 17px;
    color: #5477f0;
}

.nameAseguradora{
    background: #f7f377 !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    border-radius: 10px !important;
    text-align: center;
}